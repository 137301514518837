import {createApi} from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import axios from 'axios';
import store from '../store';

const axiosBaseQuery =
  ({baseUrl} = {baseUrl: ''}) =>
    async ({url, method = 'GET', data, params, headers}) => {
      const {session} = store.getState();
      const _headers = {
        'x-inscope-org': session.currentOrg.orgId,
        ...headers,
      }
      if (!_.isNil(session.accessToken)) {
        _headers.Authorization = `Bearer ${session.accessToken}`;
      }
      try {
        return await axios({
          url: `${baseUrl}${url}`,
          method,
          data,
          params,
          headers: _headers,
        });
      } catch (axiosError) {
        const err = axiosError
        return {
          error: {
            code: err.code,
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }

export const copilotV2ApiSlice = createApi({
  reducerPath: 'copilotV2Api',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_COPILOT_V2_API_URL,
  }),
  endpoints: builder => ({
    checkDocument: builder.mutation({
      query: ({documentId, checkType}) => ({
        url: `/suggestions/${checkType}/${documentId}`,
        method: 'POST',
      }),
    }),
    dismissDiscrepancy: builder.mutation({
      query: ({data}) => ({
        url: '/suggestions/dismiss',
        method: 'POST',
        data,
      }),
    }),
    getJobStatus: builder.query({
      query: ({jobId, checkType}) => ({
        url: `/suggestions/${jobId}/${checkType}`,
        method: 'GET',
        _retry: true,
      }),
    }),
  }),
});

export const {
  endpoints,
  useCheckDocumentMutation,
  useDismissDiscrepancyMutation,
  useLazyGetJobStatusQuery,
} = copilotV2ApiSlice;
