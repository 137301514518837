import React, {memo} from 'react';
import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
import {Box, Divider as MuiDivider} from '@mui/material';
import SinglePeriodSelect from './SinglePeriodSelect';
import PresetComparisons from './PresetComparisons';
import {GREY_BORDER} from '../../../theme';

const Divider = Styled(MuiDivider)`
    border-color: ${GREY_BORDER};
    opacity: 1;
`;

const ReportPeriodSelect = ({
  onPeriodChange,
  onComparePeriodChange,
  selectedPeriod,
  selectedComparePeriod = '',
  loading = false,
  disabled = false,
  fiscalYearEnd = '12-31', // MM-DD
  minCalendarDate = '1991-24',
}) => {
  const handlePeriodChange = (newPeriod) => {
    // Swap when clearing the primary period
    if (!newPeriod) {
      onPeriodChange(selectedComparePeriod);
      onComparePeriodChange(newPeriod);
    } else {
      onPeriodChange(newPeriod);
    }
  };

  return (
    <Box display='flex' border={`1px solid ${GREY_BORDER}`} borderRadius={2}>
      <PresetComparisons
        disabled={disabled}
        fiscalYearEnd={fiscalYearEnd}
        onOptionClick={([period, comparePeriod]) => {
          onPeriodChange(period);
          onComparePeriodChange(comparePeriod);
        }}
      />
      <SinglePeriodSelect
        onPeriodChange={handlePeriodChange}
        selectedPeriod={selectedPeriod}
        clearable={!!selectedComparePeriod}
        minCalendarDate={minCalendarDate}
        fiscalYearEnd={fiscalYearEnd}
        placeholder='Data unavailable'
        disabled={disabled}
        loading={loading}
      />
      <Divider orientation="vertical" variant="middle" flexItem />
      <SinglePeriodSelect
        onPeriodChange={onComparePeriodChange}
        selectedPeriod={selectedComparePeriod}
        minCalendarDate={minCalendarDate}
        fiscalYearEnd={fiscalYearEnd}
        placeholder={disabled ? '' : 'Add period'}
        disabled={disabled}
        clearable
        loading={loading}
      />
    </Box>
  );
};

ReportPeriodSelect.propTypes = {
  onPeriodChange: PropTypes.func.isRequired,
  onComparePeriodChange: PropTypes.func.isRequired,

  selectedPeriod: PropTypes.string.isRequired,
  selectedComparePeriod: PropTypes.string,

  fiscalYearEnd: PropTypes.string,
  minCalendarDate: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default memo(ReportPeriodSelect);
