import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {renderAmount, sortRecords} from '../util';
import {BLACK_100, WHITE_100} from '../App';
import {Typography, Box, TableCell, TableRow} from '@mui/material';
import PropTypes from 'prop-types';
import DetailTable from './DetailTable';
import Decimal from 'decimal.js-light';
import {GREEN_70} from '../theme';

const CommonDetailRow = ({idx, row, numRecords, hasHeader, compareEndDate, invertDisplayAmount}) => {
  const _bgColor = idx % 2 === 0 ? GREEN_70 : WHITE_100;
  const _borderStyle = idx === numRecords && hasHeader ? {border: 0, borderTopWidth: 1, borderColor: BLACK_100} : {border: 0};

  return (
    <TableRow key={idx} sx={{backgroundColor: _bgColor, height: '2.25rem', borderWidth: 0}}>
      <TableCell sx={{border: 0}}>
        <Box>
          <Typography>{row.label}</Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" direction="row" justifyContent="flex-end" sx={_borderStyle}>
          <Typography>{renderAmount(row.amount, invertDisplayAmount)}</Typography>
        </Box>
      </TableCell>
      {!_.isEmpty(compareEndDate) && (
        <TableCell>
          <Box display="flex" direction="row" justifyContent="flex-end" sx={_borderStyle}>
            <Typography>{renderAmount(row.compareAmount, invertDisplayAmount)}</Typography>
          </Box>
        </TableCell>
      )}
    </TableRow>
  )
}

CommonDetailRow.propTypes = {
  idx: PropTypes.number,
  row: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
    compareAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  }),
  numRecords: PropTypes.number,
  hasHeader: PropTypes.bool,
  compareEndDate: PropTypes.string,
  invertDisplayAmount: PropTypes.bool,
}

const CommonStatementDetailTable = ({tableData, totalRow, oobRow, numRecords, period, comparePeriod, generatedPeriod, hasHeader = true, reportType, invertDisplayAmount}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('label');
  const [sortedTableData, setSortedTableData] = useState([]);

  useEffect(() => {
    setSortedTableData(sortRecords(tableData, order, orderBy, orderBy === 'label' ? 'account' : 'label'));
  }, [tableData, order, orderBy])

  const _handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const getTableRow = (reportType, row, idx) => {
    if (_.isNil(row)) {
      return null;
    }

    if ((_.isNumber(row.amount) && new Decimal(row.amount).todp(0).equals(0) && _.isNil(comparePeriod)) || (_.isNumber(row.amount) && _.isNumber(row.compareAmount) && new Decimal(row.amount).todp(0).equals(0) && new Decimal(row.compareAmount).todp(0).equals(0) && !_.isEmpty(comparePeriod.endDate))) {
      return;
    }

    return (
      <CommonDetailRow
        key={idx}
        idx={idx}
        row={row}
        numRecords={numRecords}
        hasHeader={hasHeader}
        compareEndDate={_.get(comparePeriod, 'endDate', null)}
        invertDisplayAmount={invertDisplayAmount}
      />
    )
  }

  const renderTableBody = () => {
    return (
      <>
        {_.map(sortedTableData, (_data, _idx) => getTableRow(reportType, _data, _idx, numRecords, hasHeader))}
        {!_.isNil(oobRow) && getTableRow(reportType, oobRow, numRecords - 1, numRecords, hasHeader)}
        {getTableRow(reportType, totalRow, numRecords, numRecords, hasHeader)}
      </>
    )
  }

  return (
    <DetailTable
      order={order}
      orderBy={orderBy}
      handleRequestSort={_handleRequestSort}
      renderTableBody={renderTableBody}
      tableHeaders={[
        {
          label: 'Account',
          id: 'label',
        },
      ]}
      period={period}
      comparePeriod={comparePeriod}
      generatedPeriod={generatedPeriod}
      hasHeader={hasHeader}
      collapsable={false}
      reportType={reportType}
    />
  );
}

CommonStatementDetailTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  totalRow: PropTypes.object,
  oobRow: PropTypes.object,
  numRecords: PropTypes.number,
  generatedPeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  period: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }).isRequired,
  comparePeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  hasHeader: PropTypes.bool,
  reportType: PropTypes.string.isRequired,
  invertDisplayAmount: PropTypes.bool,
};

export default CommonStatementDetailTable;
