import React, {useState, memo} from 'react';
import PropTypes from 'prop-types';
import {XIcon, ChevronRightIcon, ArrowLeft, ArrowRight} from 'lucide-react';
import {usePopupState} from 'material-ui-popup-state/hooks';
import {Button, IconButton, Typography, Grid, Box, CircularProgress} from '@mui/material';
import {bindTrigger} from 'material-ui-popup-state'

import {formatDate, getUIDates, getCurrentYear, getFiscalYearStartMonth, getCurrentMonth} from '../../../util/date';
import {CascadingMenu, CascadingSubmenu, MenuItem} from '../../../components/CascadingMenu/CascadingMenu';
import {GREY_SUBTLE, GREEN_BUTTON, GREY_BORDER} from '../../../theme';

const RightIcon = <ChevronRightIcon size={16} />;

const SinglePeriodSelect = ({
  onPeriodChange,
  fiscalYearEnd,
  selectedPeriod = '',
  placeholder,
  disabled = false,
  minCalendarDate,
  clearable = false,
  loading = false,
  popupId = `SinglePeriodSelect-${+new Date()}`,
}) => {
  const popupState = usePopupState({variant: 'popover', popupId});

  const [fiscalEndMonth] = fiscalYearEnd.split('-').map(Number);
  const fiscalMonthStart = getFiscalYearStartMonth(fiscalYearEnd);
  const minDateSplit = minCalendarDate.split('-').map(Number)
  // When the min date's month is greater than the fiscal end month, it falls within the next year
  const minYear = minDateSplit[0] + Number(minDateSplit[1] > fiscalEndMonth);

  // 2025 + Number(1 < 2) -> 2025 + Number(true) -> 2025 + 1 -> 2026
  const maxYear = getCurrentYear() + Number(fiscalEndMonth < getCurrentMonth())

  const [fiscalYear, setFiscalYear] = useState(maxYear);

  const canIncrementYear = fiscalYear < maxYear;
  const canDecrementYear = fiscalYear > minYear;

  const incrementYear = () => setFiscalYear((prevYear) => prevYear + 1);
  const decrementYear = () => setFiscalYear((prevYear) => prevYear - 1);

  function getLabel () {
    if (loading) {
      return <CircularProgress size={16} />;
    }

    if (selectedPeriod) {
      return (
        <Grid container flexDirection='row'>
          <Typography variant='body2' pr={0.5}>Period:</Typography>
          <Typography variant='body2' fontWeight='bold'>{formatDate(selectedPeriod)}</Typography>
        </Grid>
      );
    }

    return (
      <Grid container>
        <Typography variant='body2' color={GREY_SUBTLE}>{placeholder}</Typography>
      </Grid>
    );
  }

  const onOptionClick = (value) => {
    popupState.close();
    onPeriodChange(value);
  }

  const {months, quarters} = getUIDates(fiscalYear, fiscalMonthStart, minCalendarDate)

  return (
    <Box width={200} height={40}>
      <Button
        {...bindTrigger(popupState)}
        color='black'
        size='large'
        fullWidth
        disabled={disabled}
        sx={{
          outline: popupState.isOpen ? `2px solid ${GREEN_BUTTON}` : '',
          height: '100%',
        }}
      >
        {getLabel()}
        {clearable && selectedPeriod && (
          <XIcon
            size={16}
            color={GREY_SUBTLE}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              popupState.close();
              onPeriodChange('');
            }}
          />
        )}
      </Button>
      <CascadingMenu popupState={popupState} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} width='248px'>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          flexDirection='row'
          pt={1} pr={2} pb={1} pl={2.5}
          borderBottom={`1px solid ${GREY_BORDER}`}
        >
         <Typography variant="caption" fontWeight="bold">FY {fiscalYear}</Typography>
         <div>
           <IconButton onClick={decrementYear} disabled={!canDecrementYear} aria-label="Previous year">
             <ArrowLeft size={16} />
           </IconButton>
           <IconButton onClick={incrementYear} disabled={!canIncrementYear} aria-label="Next year">
             <ArrowRight size={16} />
           </IconButton>
         </div>
       </Grid>
       <Grid p={1}>
        <CascadingSubmenu id='months-submenu' listItemText='Months' rightIcon={RightIcon}>
          {months.map(({label, value, calendarYear, disabled}) => (
            <MenuItem key={label} disabled={disabled} onClick={() => onOptionClick(`${calendarYear}-${value}`)}>
              {label}, {calendarYear}
            </MenuItem>
          ))}
        </CascadingSubmenu>
        <CascadingSubmenu id='quarters-submenu' listItemText='Quarters' rightIcon={RightIcon}>
          {quarters.map(({label, value, disabled}) => (
            <MenuItem key={label} disabled={disabled} onClick={() => onOptionClick(`${fiscalYear}-${value}`)}>
              {label}
            </MenuItem>
          ))}
        </CascadingSubmenu>
        {/* TODO update to send endPeriod param, API needs to be updated to handle it
        <CascadingSubmenu id='ytd-period-submenu' listItemText='YTD Period' rightIcon={RightIcon}>
          {months.map(({label, disabled}) => (
            <MenuItem key={label} disabled={disabled} onClick={() => onOptionClick(`${fiscalYear}-FY`)}>
              {label}
            </MenuItem>
          ))}
        </CascadingSubmenu>
        */}
        <MenuItem onClick={() => onOptionClick(`${fiscalYear}-FY`)}>
          Total Fiscal Year
        </MenuItem>
       </Grid>
      </CascadingMenu>
    </Box>
  );
};

SinglePeriodSelect.propTypes = {
  onPeriodChange: PropTypes.func.isRequired,
  fiscalYearEnd: PropTypes.string.isRequired,
  minCalendarDate: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.string,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  loading: PropTypes.bool,
  popupId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(SinglePeriodSelect);
