import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useAuthInfo} from '@propelauth/react';
import AppPage from './AppPage';
import CommonPageWrapper from '../components/CommonPageWrapper';
import {HomePageCard} from '../components/HomePageCard';
import TimeAgo from '../components/TimeAgo';
import {
  Avatar,
  Box,
  Grid,
  Stack,
  Typography,
  Link as MuiLink,
  Skeleton,
} from '@mui/material';
import {FileText, MessageCircle, BookOpen, PlayCircle} from 'lucide-react';
import {useGetDocumentsQuery} from '../data/documentWorkspaceApi';
import _ from 'lodash';
import parse from 'html-react-parser';
import {decode} from 'html-entities';
import PropTypes from 'prop-types';
import {subtractMonth, toMonthISO, getCurrentFiscalYear, getQuarter, MONTH_OPTIONS} from '../util/date';

const styles = {
  greeting: {
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '140%',
    color: '#162523',
  },
  card: {
    headerHeavy: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '130%',
    },
    headerLight: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '130%',
    },
    itemHeader: {
      color: '#162523',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '150%',
    },
    itemDesc: {
      color: '#7E8986',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    emptyDesc: {
      color: '#7E8986',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    reportIconContainer: {
      width: '40px',
      height: '40px',
      borderRadius: '8px',
      backgroundColor: '#26A993',
    },
    link: {
      color: '#26A993',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '150%',
    },
  },
};

const DocumentsCard = ({documents, documentsLoading}) => {
  return (
    <HomePageCard minHeight={432}>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Typography sx={styles.card.headerHeavy}>Open Reports</Typography>
        <Avatar sx={{backgroundColor: '#DCE2E0', width: 20, height: 20, marginLeft: 1}}>
          <Typography color='#162523' fontSize='12px' fontWeight={600}>{documents?.length}</Typography>
        </Avatar>
      </Box>

      <Stack mt={3}>
        {documentsLoading
          ? (
            <Stack spacing={2}>
              <Skeleton variant='rectangular' height={60} sx={{borderRadius: '4px'}} />
              <Skeleton variant='rectangular' height={60} sx={{borderRadius: '4px'}} />
            </Stack>
            )
          : documents?.length === 0
            ? (
              <Typography sx={styles.card.emptyDesc}>
                You currently have no open reports.
              </Typography>
              )
            : (
                documents?.map((doc, idx) => (
                  <Box
                    key={doc.documentId}
                    component={Link}
                    to={`/documents/${doc.documentId}`}
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    sx={{
                      p: 2,
                      borderTop: idx === 0 ? '1px solid #DFE5E2' : 'none',
                      borderBottom: '1px solid #DFE5E2',
                      textDecoration: 'none',
                      '&:hover': {
                        backgroundColor: '#F7F9F8',
                      },
                    }}
                  >
                    <Box
                      display='flex'
                      flexDirection='row'
                      alignItems='center'
                      justifyContent='center'
                      sx={styles.card.reportIconContainer}
                      flexShrink={0}
                    >
                      <FileText size={20} color='white' />
                    </Box>

                    <Box display='flex' flexDirection='column' ml={2} flex={1}>
                      <Typography sx={styles.card.itemHeader}>
                        {parse(decode(doc.name))}
                      </Typography>

                      <Typography sx={styles.card.itemDesc}>
                        Edited by {doc.updatedByName} <TimeAgo timestamp={doc.updatedAt} />
                      </Typography>
                    </Box>
                  </Box>
                ))
              )
          }
      </Stack>

      <Box sx={{mt: 'auto', pt: 2}}>
        <Link
          to='/documents'
          style={styles.card.link}
          component={Typography}
        >
          View all reports
        </Link>
      </Box>
    </HomePageCard>
  )
};

DocumentsCard.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({
    documentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    updatedByName: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  })),
  documentsLoading: PropTypes.bool.isRequired,
};

const StatementsCard = ({fiscalYearEnd, netsuiteEnabled, statementsLoading}) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth(); // 0-11

  const lastMonth = subtractMonth(today, 1);
  const lastMonthStr = toMonthISO(lastMonth.getFullYear(), lastMonth.getMonth() + 1);
  const currentMonthStr = toMonthISO(currentYear, currentMonth + 1);

  // Get fiscal year end month from MM-DD format
  const [fiscalEndMonth] = fiscalYearEnd.split('-').map(Number);
  const currentFiscalYear = getCurrentFiscalYear(fiscalEndMonth);
  const priorQuarter = getQuarter(currentMonth + 1, fiscalEndMonth) - 1;
  const priorQuarterFY = priorQuarter === 0 ? currentFiscalYear - 1 : currentFiscalYear;
  const priorQuarterNum = priorQuarter === 0 ? 4 : priorQuarter;
  const priorQuarterStr = `${priorQuarterFY}-Q${priorQuarterNum}`;
  const priorFY = `${currentFiscalYear - 1}-FY`;

  const statements = [
    {
      period: currentMonthStr,
      name: `${MONTH_OPTIONS[currentMonth].label} ${currentYear}`,
      desc: 'Current month',
    },
    {
      period: lastMonthStr,
      name: `${MONTH_OPTIONS[lastMonth.getMonth()].label} ${lastMonth.getFullYear()}`,
      desc: 'Last month',
    },
    {
      period: priorQuarterStr,
      name: `Q${priorQuarterNum} FY ${priorQuarterFY}`,
      desc: 'Last quarter',
    },
    {
      period: priorFY,
      name: `FY ${currentFiscalYear - 1}`,
      desc: 'Last fiscal year',
    },
  ];

  return (
    <HomePageCard minHeight={432}>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Typography sx={styles.card.headerHeavy}>Latest Statements</Typography>
      </Box>

      <Stack mt={3}>
        {statementsLoading
          ? (
            <Stack spacing={2}>
              <Skeleton variant='rectangular' height={60} sx={{borderRadius: '4px'}} />
              <Skeleton variant='rectangular' height={60} sx={{borderRadius: '4px'}} />
              <Skeleton variant='rectangular' height={60} sx={{borderRadius: '4px'}} />
              <Skeleton variant='rectangular' height={60} sx={{borderRadius: '4px'}} />
            </Stack>
            )
          : netsuiteEnabled !== true
            ? (
              <Typography sx={styles.card.emptyDesc}>
                Your NetSuite account must be connected to InScope to view Statements.{' '}
                <MuiLink id='get-help-button' component='span' sx={{cursor: 'pointer', color: '#7E8986'}}>
                  Request access
                </MuiLink>
              </Typography>
              )
            : (
                statements.map((statement, idx) => (
                  <Box
                    key={statement.id}
                    component={Link}
                    to={`/statements?period=${statement.period}`}
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                    sx={{
                      p: 1.5,
                      borderTop: idx === 0 ? '1px solid #DFE5E2' : 'none',
                      borderBottom: '1px solid #DFE5E2',
                      textDecoration: 'none',
                      '&:hover': {
                        backgroundColor: '#F7F9F8',
                      },
                    }}
                  >
                    <Box display='flex' flexDirection='column'>
                      <Typography sx={styles.card.itemHeader}>
                        {statement.name}
                      </Typography>

                      <Typography sx={styles.card.itemDesc}>
                        {statement.desc}
                      </Typography>
                    </Box>
                  </Box>
                ))
              )
          }
      </Stack>

      <Box sx={{mt: 'auto', pt: 2}}>
        <Link
          to='/statements'
          style={styles.card.link}
          component={Typography}
        >
          View all statements
        </Link>
      </Box>
    </HomePageCard>
  )
};

StatementsCard.propTypes = {
  fiscalYearEnd: PropTypes.string.isRequired,
  netsuiteEnabled: PropTypes.bool.isRequired,
  statementsLoading: PropTypes.bool.isRequired,
};

const HelpCard = () => {
  const helpItems = [
    {
      name: 'Ask a question',
      desc: 'Get instant help related to the product',
      icon: <MessageCircle color='#26A993' size={24} />,
    },
    {
      name: 'Disclosure Library',
      desc: 'Search disclosures and view peer examples',
      icon: <BookOpen color='#26A993' size={24} />,
      href: '/disclosure-library',
    },
    {
      name: 'Product demos',
      desc: 'Watch short videos about key features',
      icon: <PlayCircle color='#26A993' size={24} />,
      href: 'https://www.inscopehq.com/product-demo',
    },
  ];

  return (
    <HomePageCard minHeight={432}>
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Typography sx={styles.card.headerLight}>Need help?</Typography>
      </Box>

      <Stack mt={3} spacing={2}>
        {helpItems.map((item, idx) => (
          <Box
            key={item.id}
            component={Link}
            to={item.href}
            target={item.name === 'Product demos' ? '_blank' : undefined}
            display='flex'
            flexDirection='row'
            alignItems='center'
            id={item.name === 'Ask a question' ? 'get-help-button' : undefined}
            sx={{
              p: 2,
              textDecoration: 'none',
              borderRadius: '8px',
              border: '1px solid #DDE0DE',
              background: '#F2F5F4',
            }}
          >
            <Box>{item.icon}</Box>

            <Box display='flex' flexDirection='column' ml={2}>
              <Typography sx={styles.card.itemHeader}>
                {item.name}
              </Typography>

              <Typography sx={styles.card.itemDesc}>
                {item.desc}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
      <Box mt={2}>
        <Typography color='#7E8986' fontSize='14px'>
          Can&apos;t seem to find what you&apos;re looking for or need additional help?{' '}
          <MuiLink id='get-help-button' component='span' sx={{cursor: 'pointer', color: '#7E8986'}}>
            Contact us
          </MuiLink>
        </Typography>
      </Box>
    </HomePageCard>
  )
};

class HomePage extends AppPage {
  getPageTitle = () => '';
  renderBodyContent = () => <HomePageComponent />;
}

const HomePageComponent = () => {
  const {user} = useAuthInfo(state => state);
  const {currentOrg, isAuthenticated, orgConfig} = useSelector((state) => state.session);
  const [skip, setSkip] = useState(true);
  const [sortedDocuments, setSortedDocuments] = useState([]);
  const [documentsLoading, setDocumentsLoading] = useState(true);
  const [orgConfigLoading, setOrgConfigLoading] = useState(true);
  const {data: documentsData} = useGetDocumentsQuery({}, {skip, refetchOnMountOrArgChange: true, pollingInterval: 30000});

  useEffect(() => {
    setSkip(_.isNil(currentOrg) || _.isEmpty(currentOrg) || !isAuthenticated);
  }, [currentOrg, isAuthenticated]);

  useEffect(() => {
    if (documentsData) {
      const sortedDocuments = _.orderBy(documentsData, ['updatedAt'], ['desc']);
      setSortedDocuments(sortedDocuments);
      setDocumentsLoading(false);
    }
  }, [documentsData]);

  useEffect(() => {
    if (!_.isEmpty(orgConfig)) {
      setOrgConfigLoading(false);
    }
  }, [orgConfig]);

  return (
    <CommonPageWrapper height={'100%'}>
      <Stack direction={'column'} flex={1} height={'100%'}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography sx={styles.greeting} >
              Hi {_.get(user, 'firstName', 'there')}, welcome back.
            </Typography>
          </Box>

          <Box alignSelf='flex-start' pt={1}>
            {/* additional notification can go here */}
          </Box>
        </Box>

        <Grid container mt={3} spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <DocumentsCard documents={sortedDocuments?.slice(0, 3)} documentsLoading={documentsLoading} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <StatementsCard
              fiscalYearEnd={orgConfig?.fiscalYearEnd || '01-31'}
              netsuiteEnabled={!_.isEmpty(orgConfig?.onboardingCompleted)}
              statementsLoading={orgConfigLoading}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <HelpCard />
          </Grid>
        </Grid>
      </Stack>
    </CommonPageWrapper>
  )
}

HomePageComponent.propTypes = {}
export default HomePage;
