import React, {Component} from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {APP_HEADER_HEIGHT, MAX_PAGE_CONTENT_WIDTH} from '../App';

class AppPage extends Component {
  getPageTitle = () => 'Implement Me';

  renderHeaderContent = () => {
    return (
      <Box display={'flex'} justifyContent={'center'} width={'100%'} px={7.75}>
        <Box maxWidth={MAX_PAGE_CONTENT_WIDTH} width={'100%'}>
          <Stack direction="row" alignItems="center" spacing={2} pt={4} pb={4}>
            <Typography variant="h2" color="#043B34">
              {this.getPageTitle()}
            </Typography>

            {this.renderCustomHeaderContent()}
          </Stack>

          {this.renderSubHeaderContent()}
        </Box>
      </Box>
    );
  };

  // Override this method in child components to add custom header content
  renderCustomHeaderContent = () => {
    return null;
  };

  renderSubHeaderContent = () => {
    return null;
  };

  renderBodyContent = () => {
    return (
      <Typography type="body1" color="inherit" style={{flex: 1}}>Implement Me</Typography>
    )
  }

  render () {
    return (
      <Stack mt={APP_HEADER_HEIGHT} direction={'column'} width={'100%'}>
        {this.renderHeaderContent()}

        <Box display={'flex'} justifyContent={'center'} height={'100%'} px={7.75}>
          <Box maxWidth={MAX_PAGE_CONTENT_WIDTH} width={'100%'}>
            {this.renderBodyContent()}
          </Box>
        </Box>
      </Stack>
    );
  }
}

export default AppPage;
