import Box from '@mui/material/Box';
import {APP_HEADER_HEIGHT, FOOTER_HEIGHT, WHITE_100} from '../App';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Grid, Stack, Button, Table, TableContainer, TableBody, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {usePopupState} from 'material-ui-popup-state/hooks';
import {bindTrigger} from 'material-ui-popup-state'
import {ChevronDownIcon} from 'lucide-react'
import TableHeaderDateCell from './financialStatements/TableHeaderDateCell';
import {GREY_BACKGROUND_HOVER, GREY_BORDER} from '../theme';
import {CascadingMenu, MenuItem} from '../components/CascadingMenu/CascadingMenu';

const tableHeaderCellStyles = {
  padding: '0.5rem 0.5rem 0.5rem calc(0.5rem + 20px)',
  textAlign: 'center',
  borderBottom: 2,
  width: '184px',
  marginLeft: '20px!important',
  marginBottom: '-1px',
};
const RoundToDropdown = ({onChange, value}) => {
  const popupState = usePopupState({variant: 'popover', popupId: 'roundToDropdown'});

  const handleClick = (newValue) => {
    onChange(newValue);
    popupState.close();
  };

  return (
    <div>
      <Button
        {...bindTrigger(popupState)}
        variant='text'
        color='black'
        sx={{
          padding: '2px 4px',
        }}
      >
        <Typography variant="caption">
          Round numbers to:{' '}
          <Typography variant="caption" sx={{textTransform: 'capitalize'}} mr={0.5}>
            {value}
          </Typography>
          <ChevronDownIcon size={12} />
        </Typography>
      </Button>
      <CascadingMenu popupState={popupState} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} width='248px'>
        <Grid p={1}>
          <MenuItem onClick={() => handleClick('ones')}>
            Ones
          </MenuItem>
          <MenuItem disabled onClick={() => handleClick('thousands')}>
            Thousands (coming soon)
          </MenuItem>
          <MenuItem disabled onClick={() => handleClick('millions')}>
            Millions (coming soon)
          </MenuItem>
        </Grid>
      </CascadingMenu>
    </div>
  );
}

RoundToDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const ReportTableComponent = ({
  children,
  visible = true,
  period,
  comparePeriod,
  report,
  meta,
}) => {
  const {reportingPeriodMap} = useSelector((state) => state.periods);

  if (!visible || _.isNil(period) || _.isEmpty(period)) {
    return;
  }

  let _generatedPeriod;
  if (!_.isNil(meta) && _.has(meta, 'generatedPeriod')) {
    _generatedPeriod = reportingPeriodMap[meta.generatedPeriod];
  }

  return (
    <Box position="sticky" top={0} maxHeight={`calc(100vh - ${APP_HEADER_HEIGHT} - ${FOOTER_HEIGHT} - 10.25rem)`} sx={{overflowY: 'auto', borderBottom: `1px solid ${GREY_BORDER}`}}>
      <Stack
        direction="column"
        position="sticky"
        bgcolor={WHITE_100}
        top={0}
        sx={{overflow: 'hidden'}}
      >
        <Stack
          bgcolor={GREY_BACKGROUND_HOVER}
          pt={0.75}
          pb={0.75}
          pl={2}
          pr={2}
          sx={{
            borderColor: GREY_BORDER,
            borderStyle: 'solid',
            borderWidth: '1px 1px 0 1px',
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
          }}
        >
          <RoundToDropdown onChange={() => {}} value="ones" />
        </Stack>
        <Stack
          direction="row"
          bgcolor={WHITE_100}
          justifyContent="flex-end"
          border={`1px solid ${GREY_BORDER}`}
        >
          <TableHeaderDateCell
            period={period}
            generatedPeriod={_generatedPeriod}
            reportType={report?.reportType}
            sx={tableHeaderCellStyles}
          />
          {!_.isNil(comparePeriod) && (
            <TableHeaderDateCell
              period={comparePeriod}
              generatedPeriod={_generatedPeriod}
              reportType={report?.reportType}
              sx={tableHeaderCellStyles}
            />
          )}
        </Stack>
      </Stack>
      <TableContainer sx={{
        borderLeft: `1px solid ${GREY_BORDER}`,
        borderRight: `1px solid ${GREY_BORDER}`,
      }}>
        <Table className='report-table'>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

ReportTableComponent.propTypes = {
  visible: PropTypes.bool,
  period: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }).isRequired,
  comparePeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  meta: PropTypes.shape({
    generatedPeriod: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  report: PropTypes.object,
}

export default ReportTableComponent;
