import React, {createContext, useContext, useState, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';

const DrawerContext = createContext();

export const DrawerProvider = ({children}) => {
  const [drawerStates, setDrawerStates] = useState({
    comments: false,
    copilot: false,
    history: false,
  });
  const [copilotPollingState, setCopilotPollingState] = useState({
    footings: false,
    inconsistencies: false,
  });

  const toggleDrawer = useCallback((drawer) => {
    setDrawerStates((prevState) => {
      const isCurrentlyOpen = prevState[drawer];
      const newStates = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === drawer ? !isCurrentlyOpen : false;
        return acc;
      }, {});

      return newStates;
    });
  }, []);

  const openDrawer = useCallback((drawer) => {
    setDrawerStates((prevState) => {
      const newStates = Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === drawer;
        return acc;
      }, {});

      return newStates;
    });
  }, []);

  const closeDrawer = useCallback((drawer) => {
    setDrawerStates((prevState) => ({
      ...prevState,
      [drawer]: false,
    }));
  }, []);

  const isDrawerOpen = useMemo(
    () => Object.values(drawerStates).some((isOpen) => isOpen),
    [drawerStates],
  );

  const getPolling = (checkType) => copilotPollingState[checkType];

  const setPolling = (checkType, isPolling) => {
    setCopilotPollingState((prevState) => {
      return {
        ...prevState,
        [checkType]: isPolling,
      };
    });
  };

  return (
    <DrawerContext.Provider value={{
      drawerStates,
      toggleDrawer,
      openDrawer,
      closeDrawer,
      isDrawerOpen,
      setPolling,
      getPolling,
    }}>
      {children}
    </DrawerContext.Provider>
  );
};

DrawerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDrawer = () => useContext(DrawerContext);
