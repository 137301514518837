import React, {useEffect, useState} from 'react';
import Stack from '@mui/material/Stack';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import _ from 'lodash';
import {withRequiredAuthInfo} from '@propelauth/react';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {REPORT_TABS} from './constants';
import {getCurrentMonth, getCurrentYear, compareIso8601Months, toMonthISO} from '../../util/date';
import ReportPeriodSelect from './ReportPeriodSelect/ReportPeriodSelect';
import ReportTabs from './ReportTabs';
import AppPage from '../AppPage';
import CommonPageWrapper from '../../components/CommonPageWrapper';
import {withLogger} from '../../components/LoggingWrapper';
import ConfiguredReport from '../../components/financialStatements/ConfiguredReport';
import DownloadReport from '../../components/DownloadReport';
import ReportsMarketingLander from '../../components/ReportsMarketingLander';
import {
  useGetReportingPeriodsQuery,
  useGetReportsListQuery,
} from '../../data/api';
import {setSelectedComparePeriod, setSelectedPeriod} from '../../data/periods';
import {
  setComparePeriod,
  setCurrentPeriod,
} from '../../data/reports';

class ReportsPage extends AppPage {
  constructor (props) {
    super(props);

    this.state = {
      reports: [],
      compareReports: [],
      reportType: 'BALANCE_SHEET',
    };
  }

  setReportType = (_, reportType) => {
    this.setState({reportType});
  };

  getPageTitle = () => {
    const {orgConfig} = this.props.session;

    if (orgConfig && !orgConfig.onboardingCompleted) {
      return;
    }

    return 'Statements';
  };

  handlePeriodChange = (period) => {
    this.props.setSelectedPeriod({period});
    this.props.setCurrentPeriod({currentPeriod: period});
  };

  handleComparePeriodChange = (period) => {
    this.props.setSelectedComparePeriod({comparePeriod: period});
    this.props.setComparePeriod({comparePeriod: period});
  };

  // computes the earliest date that we have data for in the reporting periods
  // represented as YYYY-MM in calender year not fiscal year.
  getMinPeriodDate () {
    let minPeriodDate = toMonthISO(getCurrentYear(), getCurrentMonth());
    const periods = this.props.periods;
    if (!_.isObject(periods) || !_.isArray(periods.reportingPeriods)) {
      return minPeriodDate;
    }

    for (const period of periods.reportingPeriods) {
      if (_.isObject(period) && _.isString(period.startdate)) {
        // Extract the YYYY-MM format from YYYY-MM-DD
        const startDate = period.startdate.slice(0, 7);
        // Is the startDate ealier than the current minimum?
        if (compareIso8601Months(startDate, minPeriodDate) === -1) {
          minPeriodDate = startDate;
        }
      }
    }

    return minPeriodDate;
  }

  renderCustomHeaderContent = () => {
    const {orgConfig} = this.props.session;

    if (!orgConfig?.onboardingCompleted) return;

    const disabled = !this.props.periods.reportingPeriods.length || this.props.periods.loading;

    return (
      <Stack
        direction={'row'}
        flex={1}
        alignItems={'center'}
        justifyContent={'space-between'}
        height="100%"
      >
        <Stack alignItems="center" width="100%">
          <ReportPeriodSelect
            onPeriodChange={this.handlePeriodChange}
            onComparePeriodChange={this.handleComparePeriodChange}
            selectedPeriod={this.props.periods.selectedPeriod}
            selectedComparePeriod={this.props.periods.selectedComparePeriod}
            loading={this.props.periods.loading}
            disabled={disabled}
            minCalendarDate={this.getMinPeriodDate()}
            fiscalYearEnd={orgConfig?.fiscalYearEnd}
          />
        </Stack>
        <DownloadReport
          visible={
            !_.isEmpty(this.state.reports) &&
            (!_.has(
              this.props.session.currentOrg,
              'org_metadata.hideDownload',
            ) ||
              this.props.session.currentOrg.org_metadata.hideDownload === false)
          }
          reports={this.state.reports}
        />
      </Stack>
    );
  };

  renderSubHeaderContent = () => {
    const {orgConfig} = this.props.session;
    if (orgConfig && !orgConfig.onboardingCompleted) {
      return null;
    }

    return (
      <ReportTabs
        selectedReport={this.state.reportType}
        onChange={this.setReportType}
        reports={REPORT_TABS.filter((t) => !t.hidden)}
      />
    );
  };

  renderBodyContent = () => {
    return (
      <ReportsPageComponent
        reportType={this.state.reportType}
        setReports={(_reports) => this.setState({reports: _reports})}
      />
    );
  };
}

const ReportsPageComponent = ({reportType, setReports}) => {
  const {data: periods} = useGetReportingPeriodsQuery();
  const dispatch = useDispatch();
  const {currentOrg, orgConfig, isAuthenticated} = useSelector(
    (state) => state.session,
  );
  const {selectedPeriod: period, selectedComparePeriod: comparePeriod} =
    useSelector((state) => state.periods);
  const [skip, setSkip] = useState(true);
  const {data, refetch, isError, isSuccess} =
    useGetReportsListQuery(
      {period, comparePeriod},
      {skip},
    );
  const [loadingReports, setLoadingReports] = useState(true);
  const [reports, setLocalReports] = useState(null);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!_.isEmpty(periods)) {
      const urlPeriod = searchParams.get('period');
      if (urlPeriod) {
        dispatch(setSelectedPeriod({period: urlPeriod}));
      } else {
        dispatch(setSelectedPeriod({period: toMonthISO(getCurrentYear(), getCurrentMonth())}));
      }
    }
  }, [periods, searchParams]);

  useEffect(() => {
    setLoadingReports(true);
  }, [period, comparePeriod])

  useEffect(() => {
    if (!data) {
      return;
    }

    const _allReports = _.get(data, 'reports', []);
    const _reports = _.defaultTo(_.find(_allReports, {period}), {data: []}).data;
    setLocalReports(_reports);
    setReports(_reports);
    setLoadingReports(false);
  }, [data]);

  useEffect(() => {
    setSkip(
      _.isNil(period) ||
        _.isEmpty(period) ||
        _.isNil(currentOrg) ||
        _.isEmpty(currentOrg) ||
        !isAuthenticated,
    );
  }, [period, currentOrg, isAuthenticated]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, []);

  if (orgConfig && !orgConfig.onboardingCompleted) {
    return <ReportsMarketingLander orgName={currentOrg.orgName} />;
  }

  const activeReport = _.find(
    reports,
    (_report) => _report.reportType === reportType,
  );

  return (
    <CommonPageWrapper>
      <Stack direction="column" flex={1} justifyContent="flex-start" height="100%">
        <ConfiguredReport
          visible
          loading={loadingReports}
          hasError={isError}
          report={activeReport}
          title={activeReport?.title}
          depthCorrection={activeReport?.depthCorrection}
          period={period}
          comparePeriod={comparePeriod}
        />
      </Stack>
    </CommonPageWrapper>
  );
};

ReportsPageComponent.propTypes = {
  reportType: PropTypes.string.isRequired,
  setReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    periods: state.periods,
    session: state.session,
    reports: state.reports,
  };
};

export default withLDConsumer()(
  connect(mapStateToProps, {
    setSelectedPeriod,
    setSelectedComparePeriod,
    setCurrentPeriod,
    setComparePeriod,
  })(withRequiredAuthInfo(withLogger(ReportsPage))),
);
