import React from 'react';
import {Tabs as MuiTabs, Tab as MuiTab} from '@mui/material';
import styled from '@emotion/styled';
import {GREEN_TAB, GREY_BORDER, GREY_BACKGROUND_HOVER} from '../theme';

const TAB_HEIGHT = 40;

export const Tabs = styled((props) => (
  <MuiTabs {...props} />
))({
  border: `1px solid ${GREY_BORDER}`,
  height: TAB_HEIGHT,
  minHeight: TAB_HEIGHT,
  borderRadius: '8px',
  zIndex: 1,
  position: 'relative',
  overflow: 'visible',
  marginBottom: '20px',
  '& > .MuiTabs-scroller': {
    overflow: 'visible !important',
    height: TAB_HEIGHT - 2,
  },
  '& .MuiTabs-indicator': {
    height: TAB_HEIGHT,
    zIndex: 2,
    top: -1,
    borderRadius: '8px',
    backgroundColor: GREEN_TAB,
  },
});

export const Tab = styled((props) => (<MuiTab {...props} />))({
  height: TAB_HEIGHT - 2,
  minHeight: TAB_HEIGHT - 2,
  borderRadius: '8px',
  color: '#000',
  // delay to wait for indicator animation so there isn't a flash
  transition: 'color 300ms 150ms',
  padding: '8px 16px',
  zIndex: 3,
  flex: 1,
  textWrap: 'nowrap',
  '&:first-of-type': {
    marginLeft: 0,
    marginRight: 0,
  },
  '&:not(:first-of-type)': {
    marginLeft: '2px',
    marginRight: 0,
  },
  fontSize: '14px',
  textTransform: 'none',
  whiteSpace: 'collapse',
  wordBreak: 'keep-all',
  lineHeight: '16px',
  '&:hover:not(.Mui-selected)': {
    backgroundColor: GREY_BACKGROUND_HOVER,
  },
  '&:focus': {
    outline: `2px solid ${GREEN_TAB}`,
    zIndex: 4,
  },
  '&.Mui-selected': {
    color: '#fff',
  },
  '&.Mui-selected:focus': {
    outlineOffset: '2px',
  },
});
