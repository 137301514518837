import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import {getPeriodEndDate, getPeriodTimeframe} from '../../util';

const TableHeaderDateCell = ({period, generatedPeriod, reportType, ...stackProps}) => {
  return (
    <Stack direction={'column'} justifyContent="justify-center" {...stackProps} >
      {reportType !== 'BALANCE_SHEET' && (
        <Typography fontSize={'0.75rem'} fontWeight={400} lineHeight={'1.25rem'}>
          {getPeriodTimeframe(period, generatedPeriod)}
        </Typography>
      )}
      <Typography fontSize={'0.875rem'} fontWeight={600} lineHeight={'1.25rem'}>
        {period ? getPeriodEndDate(period, generatedPeriod) : ''}
      </Typography>
    </Stack>
  )
}

TableHeaderDateCell.propTypes = {
  period: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }).isRequired,
  generatedPeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  reportType: PropTypes.string.isRequired,
}

export default TableHeaderDateCell;
