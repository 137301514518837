export const BLACK_100 = '#0F1E24';
export const BLACK_90 = '#1E2C32';
export const WHITE_100 = '#FFF';
export const GREY_100 = '#A5B8AC';
export const GREY_90 = '#717773';
export const GREY_80 = '#DADFDB';
export const GREY_70 = '#F8FAF8';
export const GREY_BORDER = '#DFE5E2';
export const GREY_BACKGROUND_HOVER = '#F0F3F2';
export const GREY_SUBTLE = '#7F8885';
// TODO consolidate these greens for consistency
export const GREEN_BUTTON = '#26A993';
export const GREEN_NAV = '#054A41';
export const GREEN_TERTIARY = '#E9F5F0';
export const GREEN_TAB = '#135450';
export const GREEN_100 = '#2A413C';
export const GREEN_95 = '#3A5952';
export const GREEN_80 = '#D3F3C7';
export const GREEN_70 = '#ECF2EA';
export const GREEN_60 = '#77B65A';
export const GREEN_50 = '#C3F1D7';
export const COPILOT_DRAWER_WIDTH = '21.75rem';
export const COMMENT_DRAWER_WIDTH = '21.75rem';
export const HISTORY_DRAWER_WIDTH = '21.75rem';
export const DARK_TEXT_COLOR = '#162523';
export const DISABLED_TEXT_COLOR = '#9CA5A2';
