import axios from 'axios';
import store from '../store';

const _exec = async (method, request) => {
  const {session} = store.getState();

  return (await axios.request({
    method,
    headers: {
      Authorization: `Bearer ${session.accessToken}`,
      'x-inscope-org': session.currentOrg.orgId,
    },
    baseURL: process.env.REACT_APP_BASE_API_URL,
    ...request,
  })).data
}

const _get = (path, {params = {}}) => {
  return _exec(
    'get',
    {
      url: path,
      params,
    },
  )
}

const _post = async (path, {params = {}, body}) => {
  return _exec(
    'post',
    {
      url: path,
      data: body,
      params,
    },
  )
}

export const downloadReports = (body, params) => {
  return _post('/app/reports/download', {body, params});
}

export const getDepartments = () => {
  return _get('/app/departments', {})
}

// SCF Specific
export const getFile = (fileId) => {
  return _get(`/app/report/${fileId}/workbook`, {})
}

export const getFSLIs = () => {
  return _get('/app/fslis', {})
}
