import {REPORT_TYPES} from '../../constants';

export const REPORT_TABS = [
  {
    reportType: 'BALANCE_SHEET',
    ...REPORT_TYPES.BALANCE_SHEET,
  },
  {
    reportType: 'INCOME_STATEMENT_GAAP',
    ...REPORT_TYPES.INCOME_STATEMENT_GAAP,
  },
  {
    reportType: 'INCOME_STATEMENT_NON_GAAP',
    ...REPORT_TYPES.INCOME_STATEMENT_NON_GAAP,
  },
  {
    reportType: 'INDIRECT_CASHFLOW',
    ...REPORT_TYPES.INDIRECT_CASHFLOW,
    disabled: true,
  },
  {
    reportType: 'DIRECT_CASHFLOW',
    ...REPORT_TYPES.DIRECT_CASHFLOW,
    hidden: true,
  },
  {
    label: 'SSE (coming soon)',
    reportType: 'SSE',
    hidden: true,
  },
  {
    label: 'SCI (coming soon)',
    reportType: 'SCI',
    hidden: true,
  },
];
