import React from 'react'

import {
  Avatar,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from '@mui/material';

import NetsuiteGraphic from '../assets/netsuite-integration.png';
import CheckIcon from '@mui/icons-material/Check';
import {GREEN_NAV, GREEN_50} from '../App';

import PropTypes from 'prop-types';

const bullets = [
  'Generate your GAAP and non-GAAP financial reports',
  'Produce GAAP indirect cash flow statement and workbook in one click',
  'Understand cash flow movements with direct cash flow reporting',
  'Reduce cost and improve accuracy through automation',
  'Industry leading security practices (SOC 2 Type I and SOC 2 Type II)',
];

const ReportsMarketingLander = ({orgName}) => {
  const buttonStyles = {
    width: 183,
    height: 40,
    borderRadius: '8px',
    backgroundColor: '#37CF79',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#37CF79',
      boxShadow: 'none',
      border: 'none',
    },
  };

  const mailToString = `mailto:info@inscopehq.com?subject=${orgName}%3A%20access%20to%20financial%20reporting&body=I%E2%80%99m%20interested%20to%20learn%20more%20about%20automating%20financial%20reporting%20with%20InScope-Netsuite%20integration.%20Let%27s%20chat%3F`

  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
      <Stack mt={3} direction={'column'} flex={1} spacing={4} alignItems='center' maxWidth={650}>
        <img src={NetsuiteGraphic} height={33} width={229} />

        <Typography variant='h1' color={GREEN_NAV} textAlign='center'>
          Connect your Netsuite to unlock monthly, quarterly and annual reporting
        </Typography>

        <Divider sx={{width: '100%', backgroundColor: '#DDE0DE'}} />

        <Box>
          {bullets.map((bullet, index) => (
            <Box key={index} display='flex' flexDirection='row' alignItems='center'>
              <Avatar sx={{backgroundColor: GREEN_50, width: 24, height: 24}}>
                <CheckIcon sx={{fontSize: '12px', color: GREEN_NAV}} />
              </Avatar>
              <Typography ml={1} my={1} variant='body1' fontWeight={400} textAlign='left' fontSize={16} color={GREEN_NAV}>{bullet}</Typography>
            </Box>
          ))}
        </Box>

        <Divider sx={{width: '100%', backgroundColor: '#DDE0DE'}} />

        <Typography variant='body2' fontWeight='bold' color={GREEN_NAV}>
          To access this integration, you need to upgrade your account.
        </Typography>

        <Button variant='outlined' href={mailToString} sx={buttonStyles}>
          <Typography variant='body2' fontWeight={500}>Request access</Typography>
        </Button>
      </Stack>
    </Box>
  );
}

ReportsMarketingLander.propTypes = {
  orgName: PropTypes.string.isRequired,
};

export default ReportsMarketingLander;
