import React from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab} from '../../components/Tabs';

const ReportTabs = ({selectedReport, onChange, reports}) => {
  return (
    <Tabs
      width='100%'
      value={selectedReport}
      onChange={onChange}
    >
      {reports.map(({label, title, disabled, reportType}) => (
        <Tab
          key={label}
          label={title}
          disabled={disabled}
          value={reportType}
        />
      ))}
    </Tabs>
  );
};

ReportTabs.propTypes = {
  selectedReport: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  reports: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    reportType: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  })).isRequired,
};

export default ReportTabs;
