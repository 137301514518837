import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import spacetime from 'spacetime';
import _ from 'lodash';
import {
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentQuery,
  useCreateUploadFileUrlMutation,
} from '../../data/documentWorkspaceApi';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Radio from '@mui/material/Radio';
import Loading from '../Loading';
import {BLACK_100, DARK_TEXT_COLOR, WHITE_100} from '../../theme';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {CREATE_FLOWS, CREATE_STEPS, DOCUMENT_TYPES, ROLL_FORWARD_STATUSES} from '../../constants';
import {useNavigate} from 'react-router-dom';
import Dropzone from 'react-dropzone';
import {Upload} from 'lucide-react';
import parse from 'html-react-parser';
import {decode} from 'html-entities';

const yearList = [
  spacetime.now().subtract(5, 'year').format('year'),
  spacetime.now().subtract(4, 'year').format('year'),
  spacetime.now().subtract(3, 'year').format('year'),
  spacetime.now().subtract(2, 'year').format('year'),
  spacetime.now().subtract(1, 'year').format('year'),
  spacetime.now().format('year'),
  spacetime.now().add(1, 'year').format('year'),
  spacetime.now().add(2, 'year').format('year'),
]

const QUARTERS = _.flatten(_.map(yearList, (year) => [
  `Q1 ${year}`,
  `Q2 ${year}`,
  `Q3 ${year}`,
  `Q4 ${year}`,
]));

const YEARS = _.map(yearList, (year) => ({
  label: `FY ${year}`,
  value: year,
}))

const _getDocumentName = (documentType, base, reportPeriod) => {
  if (_.isNil(base) || _.isEmpty(base)) {
    console.warn('Document name base is empty');
  }
  switch (documentType) {
    case 'ANNUAL':
      if (_.isNil(base) || _.isEmpty(base)) {
        return `${reportPeriod} - Annual Report`;
      }
      return `${base} - ${reportPeriod} - Annual Report`;
    case 'QUARTER':
      if (_.isNil(base) || _.isEmpty(base)) {
        return `${reportPeriod} - Quarterly Report`;
      }
      return `${base} - ${reportPeriod} - Quarterly Report`;
    default:
      return base;
  }
}

const styles = {
  createButton: {
    background: '#26A993',
    borderRadius: '0.5rem',
    paddingLeft: '0.75rem',
    paddingRight: '1rem',
    paddingVertical: '0.625rem',
    height: '2.25rem',
    color: WHITE_100,
    '&:hover': {
      backgroundColor: '#229884',
    },
    '&:disabled': {
      backgroundColor: '#81C3B8',
    },
  },
  createButtonLabel: {
    fontSize: '0.875rem',
    lineHeight: '140%',
    fontWeight: '500',
    color: WHITE_100,
  },
  cancelButton: {
    borderWidth: 0,
    paddingVertical: '0.6825rem',
    paddingHorizontal: '1rem',
    height: '2.25rem',
    '&:hover': {
      backgroundColor: '#F2F5F4',
      borderRadius: '0.5rem',
      borderWidth: 0,
    },
  },
  cancelButtonLabel: {
    color: '#043B34',
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: '130%',
  },
  createOption: {
    selected: {
      cursor: 'pointer',
      borderRadius: '0.5rem',
      border: '1px solid #DDE0DE',
      background: 'linear-gradient(0deg, #F3F4F4 0%, #F3F4F4 100%), linear-gradient(0deg, #FFF 0%, #FFF 100%), #E5F6EC',
    },
    disabled: {
      borderRadius: '0.5rem',
      border: '1px solid #DDE0DE',
      background: WHITE_100,
      opacity: 0.5,
    },
    default: {
      cursor: 'pointer',
      borderRadius: '0.5rem',
      border: '1px solid #DDE0DE',
      background: WHITE_100,
    },
    label: {
      color: '#043B34',
      fontSize: '0.875rem',
      fontWeight: '600',
      lineHeight: '140%',
    },
  },
  modal: {
    base: {
      borderRadius: '0.5rem',
      boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
      backgroundColor: WHITE_100,
      height: '100%',
    },
    title: {
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: '120%',
      color: DARK_TEXT_COLOR,
    },
    divider: {
      borderBottomWidth: '0.0625rem',
      opacity: 1,
      color: '#DDe0DE',
    },
    dropzone: {
      border: '2px dashed #C7D0CD',
      backgroundColor: '#F2F5F4',
      borderRadius: '0.5rem',
      padding: '1rem',
      height: '5rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dropzoneLabel: {
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '140%',
      color: '#67716E',
    },
    boldLabel: {
      fontSize: '0.875rem',
      fontWeight: '700',
      lineHeight: '140%',
      color: DARK_TEXT_COLOR,
    },
    textField: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '0.5rem',
        height: '2.5rem',
      },
    },
    select: {
      borderRadius: '0.5rem',
      height: '2.5rem',
    },
    note: {
      color: '#67716E',
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '140%',
      letterSpacing: '0.0075em',
    },
    label: {
      fontSize: '0.875rem',
      fontWeight: '500',
      lineHeight: '140%',
      color: DARK_TEXT_COLOR,
    },
    reportTypeButton: {
      selectedContainer: {
        backgroundColor: WHITE_100,
        borderRadius: '0.375rem',
        paddingVertical: '0.25rem',
        paddingHorizontal: '1rem',
        gap: '0.5rem',
        height: '2.5rem',
        border: '2px solid #26A993',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      unselectedContainer: {
        backgroundColor: WHITE_100,
        borderRadius: '0.375rem',
        paddingVertical: '0.25rem',
        paddingHorizontal: '1rem',
        gap: '0.5rem',
        height: '2.5rem',
        border: '2px solid #DFE6E3',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      radio: {
        padding: 0,
        color: '#C7D0CD',
        '&.Mui-checked': {
          backgroundColor: '#26A993',
          borderColor: '#26A993',
          borderRadius: '50%',
          padding: 0,
          color: '#26A993',
          '& .MuiSvgIcon-root': {
            '&:last-child': { // inner dot
              transform: 'scale(1.2)',
              color: '#FFFFFF',
            },
          },
        },
      },
      label: {
        fontSize: '0.875rem',
        fontWeight: '500',
        lineHeight: '140%',
        color: DARK_TEXT_COLOR,
      },
    },
    stepLabel: {
      color: '#043B34',
      opacity: 0.5,
      fontSize: '0.75rem',
      fontWeight: '500',
      lineHeight: '140%',
    },
    heavy: {
      fontSize: '0.8125rem',
      fontWeight: '700',
      lineHeight: '150%',
      color: '#043B34',
    },
  },
  processing: {
    processingLabel: {
      color: '#EC881B',
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '140%',
    },
    completedLabel: {
      color: '#043B34',
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '140%',
    },
    failedLabel: {
      color: '#043B34',
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '140%',
    },
    label: {
      color: '#043B34',
      fontSize: '0.75rem',
      fontWeight: '500',
      lineHeight: '140%',
    },
  },
}

const Processing = ({isSuccess, isError}) => {
  if (isSuccess) {
    return (
      <Stack direction={'row'} spacing={'0.5rem'} width={'5.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
        <CheckIcon fontSize={'1.25rem'} sx={{color: '#25C489'}}/>
        <Typography style={styles.processing.completedLabel}>Completed</Typography>
      </Stack>
    );
  } else if (isError) {
    return (
      <Stack direction={'row'} spacing={'0.5rem'} width={'5.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
        <ErrorOutlineIcon fontSize={'1.25rem'} sx={{color: '#f44336'}}/>
        <Typography style={styles.processing.failedLabel}>Failed</Typography>
      </Stack>
    );
  }
  return (
    <Stack direction={'row'} spacing={'0.5rem'} width={'5.5rem'} alignItems={'center'} justifyContent={'flex-end'}>
      <CircularProgress size={'1.25rem'} sx={{color: '#EC881B'}}/>
      <Typography style={styles.processing.processingLabel}>Processing</Typography>
    </Stack>
  );
}

Processing.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
}

const ProcessingContainer = ({documentId, onComplete}) => {
  const {isAuthenticated} = useSelector((state) => state.session);
  const [skip, setSkip] = useState(true);
  const [numFailures, setNumFailures] = useState(0);

  useEffect(() => {
    setSkip(!open || _.isNil(documentId) || !isAuthenticated)
  }, [documentId, open, isAuthenticated]);

  const {data, isLoading, isError, isSuccess} = useGetDocumentQuery({documentId}, {refetchOnMountOrArgChange: true, skip, pollingInterval: 5000});

  useEffect(() => {
    if (isSuccess && _.includes([ROLL_FORWARD_STATUSES.completed, ROLL_FORWARD_STATUSES.failed], _.get(data, 'record.rollForwardStatus'))) {
      setSkip(true);

      if (_.get(data, 'record.rollForwardStatus') === ROLL_FORWARD_STATUSES.completed) {
        onComplete();
      }
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError && numFailures === 12) {
      console.error('Too many failures');
      setSkip(true);
    } else {
      setNumFailures(numFailures + 1);
    }
  }, [isError]);

  return (
    <Box width={'100%'} display={'flex'} flex={1} flexDirection={'column'} justifyContent={'center'}>
      <List width={'100%'}>
        <ListItem divider={true}>
          <Stack direction={'row'} flex={1} spacing={'0.5rem'} width={'100%'} justifyContent={'space-between'} height={'1.5rem'} alignItems={'center'}>
            <Typography style={styles.processing.label}>Rolling forward dates</Typography>
            <Processing isProcessing={isLoading || _.get(data, 'record.rollForwardStatus') === 'PROCESSING'} isSuccess={_.get(data, 'record.rollForwardStatus') === 'COMPLETED'} isError={_.get(data, 'record.rollForwardStatus') === 'FAILED'} />
          </Stack>
        </ListItem>
        <ListItem divider={true}>
          <Stack direction={'row'} flex={1} spacing={'0.5rem'} width={'100%'} justifyContent={'space-between'} height={'1.5rem'} alignItems={'center'}>
            <Typography style={styles.processing.label}>Rolling forward tables</Typography>
            <Processing isProcessing={isLoading || _.get(data, 'record.rollForwardStatus') === 'PROCESSING'} isSuccess={_.get(data, 'record.rollForwardStatus') === 'COMPLETED'} isError={_.get(data, 'record.rollForwardStatus') === 'FAILED'} />
          </Stack>
        </ListItem>
        <ListItem divider={true}>
          <Stack direction={'row'} flex={1} spacing={'0.5rem'} width={'100%'} justifyContent={'space-between'} height={'1.5rem'} alignItems={'center'}>
            <Typography style={styles.processing.label}>Rolling forward disclosures</Typography>
            <Processing isProcessing={isLoading || _.get(data, 'record.rollForwardStatus') === 'PROCESSING'} isSuccess={_.get(data, 'record.rollForwardStatus') === 'COMPLETED'} isError={_.get(data, 'record.rollForwardStatus') === 'FAILED'} />
          </Stack>
        </ListItem>
        <ListItem divider={true}>
          <Stack direction={'row'} flex={1} spacing={'0.5rem'} width={'100%'} justifyContent={'space-between'} height={'1.5rem'} alignItems={'center'}>
            <Typography style={styles.processing.label}>Rolling tie-out support workbook</Typography>
            <Processing isProcessing={isLoading || _.get(data, 'record.rollForwardStatus') === 'PROCESSING'} isSuccess={_.get(data, 'record.rollForwardStatus') === 'COMPLETED'} isError={_.get(data, 'record.rollForwardStatus') === 'FAILED'} />
          </Stack>
        </ListItem>
      </List>
    </Box>
  )
};

ProcessingContainer.propTypes = {
  documentId: PropTypes.string,
  onComplete: PropTypes.func,
}

const CreateDocumentModal = ({open, onClose, onError, previousDocuments = [], previousDocumentId, createFlow, existingDocumentId, onRollForwardComplete}) => {
  const navigate = useNavigate();
  const {orgConfig} = useSelector((state) => state.session);
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState(DOCUMENT_TYPES.annualReport);
  const [reportPeriod, setReportPeriod] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [documentName, setDocumentName] = useState(_getDocumentName(documentType, _.get(orgConfig, 'companyLegalName', orgConfig?.name), reportPeriod));
  const [documentNameIsDirty, setDocumentNameIsDirty] = useState(false);
  const [documentNameError, setDocumentNameError] = useState(false);
  const [documentNameErrorMessage, setDocumentNameErrorMessage] = useState('');
  const [step, setStep] = useState(null);
  const [createDocument, {reset, data}] = useCreateDocumentMutation();
  const [createUploadFileUrl] = useCreateUploadFileUrlMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [deleteDocument, {isLoading: deleteDocumentLoading, isSuccess: deleteDocumentSuccess, isError: deleteDocumentError, reset: deleteDocumentReset}] = useDeleteDocumentMutation();
  const [documentId, setDocumentId] = useState(existingDocumentId);
  const [previousDocument, setPreviousDocument] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isRollForwardComplete, setIsRollForwardComplete] = useState(false);
  const [years, setYears] = useState(YEARS);

  const _onClose = () => {
    onClose();
  }

  const _getPreviousDocument = () => {
    if (createFlow === CREATE_FLOWS.rollforward) {
      const _existingDocument = _.find(previousDocuments, {documentId: existingDocumentId});
      if (!_.isNil(_existingDocument)) {
        return _.find(previousDocuments, {documentId: _existingDocument.previousDocumentId});
      } else if (!_.isNil(previousDocumentId)) {
        return _.find(previousDocuments, {documentId: previousDocumentId});
      }
      return null;
    }

    return null
  }

  const _buildYearsList = previousFiscalYear => {
    let _years = YEARS;
    if (!_.some(_years, _year => _year.value === previousFiscalYear)) {
      _years = _.sortBy([..._years, {
        label: `FY ${previousFiscalYear}`,
        value: previousFiscalYear,
      }], 'value');
    }
    return _years;
  }

  useEffect(() => {
    if (open && !isOpen) { // Only run reset when opening for first time
      const _quarter = spacetime.now().format('quarter');
      const _previousDocument = _getPreviousDocument();
      let _fiscalYear = spacetime.now().format('year');
      if (!_.isNil(_previousDocument)) {
        _fiscalYear = `${Number(_previousDocument.fiscalYear) + 1}`;
      }
      setDocumentType(DOCUMENT_TYPES.annualReport);
      setReportPeriod(`${_fiscalYear}`);
      setDocumentName(_getDocumentName(documentType, _.get(orgConfig, 'companyLegalName', orgConfig?.name), _fiscalYear, _quarter));
      setDocumentNameIsDirty(false);
      setDocumentNameError(false);
      setDocumentNameErrorMessage('');
      setStep(createFlow === CREATE_FLOWS.annualRollForward ? _.isNil(existingDocumentId) ? CREATE_STEPS.create : CREATE_STEPS.processing : null);
      setDocumentId(existingDocumentId);
      setPreviousDocument(_previousDocument);
      setYears(_buildYearsList(_fiscalYear));
      setIsRollForwardComplete(false);
      setIsOpen(true);
      setPdfFile(null);
      setLoading(false);
    } else if (!open) { // Only close when actually closing
      setIsOpen(false);
    }
  }, [createFlow, existingDocumentId, open, documentType]);

  useEffect(() => {
    if (documentType === DOCUMENT_TYPES.annualReport) {
      setReportPeriod(`${spacetime.now().format('year')}`);
    } else {
      setReportPeriod(`${spacetime.now().format('quarter')} ${spacetime.now().format('year')}`);
    }
  }, [documentType]);

  useEffect(() => {
    if (orgConfig && !documentNameIsDirty) {
      setDocumentName(_getDocumentName(documentType, documentType === DOCUMENT_TYPES.other ? '' : _.get(orgConfig, 'companyLegalName', orgConfig.name), reportPeriod));
    }
  }, [orgConfig, documentType, reportPeriod, documentNameIsDirty]);

  // useEffect(() => {
  //   if (isSuccess) {
  //     if (createFlow === CREATE_FLOWS.annualRollForward) {
  //       setStep(CREATE_STEPS.processing);
  //       setDocumentId(_.get(data, 'documentId'));
  //     } else {
  //       _onClose();
  //     }
  //   }
  //   reset();
  // }, [isSuccess]);

  // useEffect(() => {
  //   if (isError) {
  //     console.error('Error creating document', {error});
  //     if (_.get(error, 'data.code') === 'DOCUMENT_EXISTS') {
  //       if (documentType !== DOCUMENT_TYPES.annualReport) {
  //         setDocumentNameError(true);
  //         setDocumentNameErrorMessage('Filename exists. Please choose another.');
  //       } else {
  //         onError('Filename exists. Please choose another.');
  //       }
  //     } else {
  //       onError();
  //     }
  //     reset();
  //   }
  // }, [isError]);

  useEffect(() => {
    if (deleteDocumentSuccess) {
      _onClose();
      deleteDocumentReset();
    }
  }, [deleteDocumentSuccess]);

  useEffect(() => {
    if (deleteDocumentError) {
      console.error('Error deleting document', {deleteDocumentError});
      onError('There was a problem processing your request. Please try again later.');
      deleteDocumentReset();
    }
  }, [deleteDocumentError]);

  const _getModalTitle = () => {
    switch (createFlow) {
      case CREATE_FLOWS.rollforward:
        return 'report - Rollforward existing doc.';
      case CREATE_FLOWS.blankDocument:
        return 'report';
      case CREATE_FLOWS.pdf:
        return 'report - Rollforward a PDF file';
      default:
        return 'document';
    }
  }

  const _handleChange = (field) => (event) => {
    const _value = event.target.value;
    switch (field) {
      case 'reportPeriod':
        setReportPeriod(_value);
        if (!documentNameIsDirty) {
          setDocumentName(_getDocumentName(documentType, _.get(orgConfig, 'companyLegalName', orgConfig.name), _value))
        }
        break;
      case 'previousDocument': {
        const _previousDocument = _.find(previousDocuments, {documentId: _value});
        setPreviousDocument(_previousDocument);
        if (!_.isNil(_previousDocument)) {
          const _fiscalYear = `${Number(_previousDocument.fiscalYear) + 1}`;
          setReportPeriod(documentType === DOCUMENT_TYPES.annualReport ? `${_fiscalYear}` : `${_previousDocument.quarter} ${_fiscalYear}`);
          setYears(_buildYearsList(_fiscalYear));
        }
        break;
      }
      default:
        setDocumentName(_value);
        setDocumentNameIsDirty(true);
    }
  }

  const _renderPeriod = () => {
    if (_.includes([CREATE_FLOWS.rollforward, CREATE_FLOWS.blankDocument, CREATE_FLOWS.pdf], createFlow)) {
      return (
        <Stack direction={'row'} spacing={'1rem'}>
          <Stack direction={'column'} spacing={'0.5rem'} flex={1}>
            <Typography style={styles.modal.label}>Report period *</Typography>
            <FormControl>
              <Select
                id="createDocument-reportPeriod-select"
                value={reportPeriod}
                onChange={_handleChange('reportPeriod')}
                sx={styles.modal.select}
              >
                {documentType === DOCUMENT_TYPES.quarterlyReport && _.map(QUARTERS, _quarter => <MenuItem key={_quarter} value={_quarter}>{_quarter}</MenuItem>)}
                {documentType === DOCUMENT_TYPES.annualReport && _.map(years, _year => <MenuItem key={_year.value} value={_year.value}>{_year.label}</MenuItem>)}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      );
    }
  }

  const _renderDocumentName = () => {
    return (
      <Stack direction={'column'} spacing={'0.5rem'}>
        <Typography style={styles.modal.label}>Report name *</Typography>
        <TextField sx={styles.modal.textField} id="createDocument-documentName" variant="outlined" value={documentName} onChange={_handleChange('documentName')} error={documentNameError} helperText={documentNameErrorMessage} />
      </Stack>
    );
  }

  const _createDocument = async () => {
    setDocumentNameError(false);
    setDocumentNameErrorMessage('');
    setLoading(true);
    try {
      let _quarter = null;
      let _fiscalYear = null;
      if (documentType === DOCUMENT_TYPES.quarterlyReport) {
        [_quarter, _fiscalYear] = reportPeriod.split(' ');
      } else {
        _fiscalYear = reportPeriod;
      }

      const _payload = {
        name: documentName,
        type: documentType,
        status: 'DRAFT',
        fiscalYear: _fiscalYear,
        quarter: _quarter,
      }

      if (createFlow === CREATE_FLOWS.rollForward) {
        _payload.previousDocumentId = _.get(previousDocument, 'documentId');
        _payload.isRollForward = true;
        _payload.rollForwardStatus = 'NOT_STARTED';
        _payload.rollForwardSource = 'DOCUMENT';
        _payload.status = 'PENDING';
      } else if (createFlow === CREATE_FLOWS.pdf) {
        _payload.isRollForward = true;
        _payload.rollForwardStatus = 'NOT_STARTED';
        _payload.rollForwardSource = 'PDF';
        _payload.status = 'PENDING';
      }

      const _document = await createDocument(_.omitBy(_payload, _.isNil));
      if (!_.has(_document, 'error')) {
        if (createFlow === CREATE_FLOWS.pdf) {
          const _uploadFileUrl = await createUploadFileUrl({
            documentId: _.get(_document.data, 'documentId'),
            fileName: `${crypto.randomUUID()}.${pdfFile.name.split('.').pop()}`,
            originalFileName: pdfFile.name,
            originalFilePath: pdfFile.path,
          });

          if (!_.has(_uploadFileUrl, 'error')) {
            await fetch(_uploadFileUrl.data.uploadUrl, {
              method: 'PUT',
              body: pdfFile,
              headers: {
                'Content-Type': pdfFile.type,
              },
            });

            await updateDocument({
              documentId: _.get(_document, 'data.documentId'),
              data: {
                rollForwardSourceDocumentKey: _.get(_uploadFileUrl, 'data.key'),
              },
            });
          }

          if (_.includes([CREATE_FLOWS.rollforward, CREATE_FLOWS.pdf], createFlow)) {
            setStep(CREATE_STEPS.processing);
            setDocumentId(_.get(data, 'documentId'));
          } else {
            _onClose();
          }
        } else {
          console.error('Error generating upload URL', {error: _document.error});
          onError('There was a problem processing your request. Please try again later.');
          reset();
        }
      } else {
        console.error('Error creating document', {error: _document.error});
        if (_.get(_document.error, 'data.code') === 'DOCUMENT_EXISTS') {
          if (documentType !== DOCUMENT_TYPES.annualReport) {
            setDocumentNameError(true);
            setDocumentNameErrorMessage('Filename exists. Please choose another.');
          } else {
            onError('Filename exists. Please choose another.');
          }
        } else {
          onError();
        }
        reset();
      }
    } catch (error) {
      console.error('Error creating document', {error});
      if (_.get(error, 'data.code') === 'DOCUMENT_EXISTS') {
        if (documentType !== DOCUMENT_TYPES.annualReport) {
          setDocumentNameError(true);
          setDocumentNameErrorMessage('Filename exists. Please choose another.');
        } else {
          onError('Filename exists. Please choose another.');
        }
      } else {
        onError();
      }
      reset();
    } finally {
      setLoading(false);
    }
  }

  const _validateInputs = () => {
    let _hasFilename = true;
    let _hasPreviousDocument = true;
    if (createFlow === CREATE_FLOWS.pdf && _.isNil(pdfFile)) {
      _hasFilename = false;
    }
    if (createFlow === CREATE_FLOWS.rollforward && _.isNil(previousDocument)) {
      _hasPreviousDocument = false;
    }
    switch (documentType) {
      case DOCUMENT_TYPES.annualReport:
        return !_.isEmpty(reportPeriod) && !_.isEmpty(documentName) && _hasFilename && _hasPreviousDocument;
      case DOCUMENT_TYPES.quarterlyReport:
        return !_.isEmpty(reportPeriod) && !_.isEmpty(documentName) && _hasFilename && _hasPreviousDocument;
      default:
        return !_.isEmpty(documentName) && _hasFilename && _hasPreviousDocument;
    }
  }

  const _renderModalTitle = () => {
    return `Create ${_getModalTitle()}`;
  }

  const _renderPreviousDocumentList = () => {
    if (createFlow !== CREATE_FLOWS.rollforward) {
      return null;
    }
    return (
      <Stack direction={'column'} spacing={'1rem'} padding={'1.5rem'} backgroundColor={'#F2F5F4'}>
        <Typography style={styles.modal.boldLabel}>Existing document to rollforward:</Typography>
        <FormControl>
          <Select
            id="createDocument-previousDocument-select"
            value={_.get(previousDocument, 'documentId')}
            onChange={_handleChange('previousDocument')}
            sx={styles.modal.select}
          >
            {_.map(previousDocuments, _document => <MenuItem key={_document.documentId} value={_document.documentId}>{parse(decode(_document.name))}</MenuItem>)}
          </Select>
        </FormControl>
      </Stack>
    )
  }

  const _renderImportFile = () => {
    if (createFlow !== CREATE_FLOWS.pdf) {
      return null;
    }

    const _getLabel = (isDragActive) => {
      if (!_.isNil(pdfFile)) {
        return <Typography sx={styles.modal.dropzoneLabel}>{pdfFile.name}</Typography>;
      } else if (isDragActive) {
        return <Typography sx={styles.modal.dropzoneLabel}>Drop the files here ...</Typography>;
      } else {
        return <Typography sx={styles.modal.dropzoneLabel}>Drag and drop PDF file here or <Box component="span" sx={{textDecoration: 'underline'}}>select file</Box></Typography>;
      }
    }

    return (
      <Stack direction={'column'} spacing={'1rem'} padding={'1.5rem'} backgroundColor={'#F2F5F4'}>
        <Typography style={styles.modal.boldLabel}>File to import and rollforward:</Typography>
        <Dropzone onDrop={acceptedFiles => setPdfFile(acceptedFiles[0])}>
          {({getRootProps, getInputProps, isDragActive}) => (
            <Stack
              direction={'row'}
              spacing={'0.5rem'}
              sx={{
                ...styles.modal.dropzone,
                cursor: 'pointer',
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Upload size={'1rem'} />
              {_getLabel(isDragActive)}
            </Stack>
          )}
        </Dropzone>
      </Stack>
    );
  }

  const _renderReportType = () => {
    return (
      <Stack direction={'column'} spacing={'0.5rem'}>
        <Typography style={styles.modal.label}>Report type *</Typography>
        <Stack direction={'row'} spacing={'1rem'}>
          <Button onClick={() => setDocumentType(DOCUMENT_TYPES.annualReport)} sx={documentType === DOCUMENT_TYPES.annualReport ? styles.modal.reportTypeButton.selectedContainer : styles.modal.reportTypeButton.unselectedContainer}>
            <Radio
              sx={styles.modal.reportTypeButton.radio}
              checked={documentType === DOCUMENT_TYPES.annualReport}
              value={DOCUMENT_TYPES.annualReport}
              name={DOCUMENT_TYPES.annualReport}
            />
            <Typography style={styles.modal.reportTypeButton.label}>Annual report</Typography>
          </Button>
          <Button onClick={() => setDocumentType(DOCUMENT_TYPES.quarterlyReport)} sx={documentType === DOCUMENT_TYPES.quarterlyReport ? styles.modal.reportTypeButton.selectedContainer : styles.modal.reportTypeButton.unselectedContainer}>
            <Radio
              sx={styles.modal.reportTypeButton.radio}
              checked={documentType === DOCUMENT_TYPES.quarterlyReport}
              value={DOCUMENT_TYPES.quarterlyReport}
              name={DOCUMENT_TYPES.quarterlyReport}
            />
            <Typography style={styles.modal.reportTypeButton.label}>Quarterly report</Typography>
          </Button>
        </Stack>
      </Stack>
    );
  }

  const _onRollForwardComplete = () => {
    setIsRollForwardComplete(true);
    onRollForwardComplete();
  }

  const _renderCreate = () => {
    if (createFlow === (CREATE_FLOWS.annualRollForward || CREATE_FLOWS.pdf) && step === CREATE_STEPS.processing) {
      return (
        <Stack direction={'column'} justifyContent={'space-between'} flex={1}>
          <React.Fragment>
            <Typography style={styles.modal.heavy}>We&apos;ve initiated the roll forward of your {_.get(previousDocument, 'fiscalYear')} report.</Typography>
            <Typography style={styles.modal.label}>This can take several minutes&mdash;we&apos;ll email you when the process is complete.</Typography>
          </React.Fragment>
          <ProcessingContainer documentId={documentId} onComplete={_onRollForwardComplete} />
        </Stack>
      )
    } else {
      return (
        <React.Fragment>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography style={styles.modal.boldLabel}>New document details</Typography>
            <Typography style={styles.modal.note}>* Required info</Typography>
          </Stack>
          {_renderReportType()}
          {_renderPeriod()}
          {_renderDocumentName()}
        </React.Fragment>
      );
    }
  }

  const _deleteDocument = async () => {
    await deleteDocument({
      documentId,
    });
    onClose();
  }

  const _renderCTAButton = () => {
    if (createFlow === CREATE_FLOWS.annualRollForward && step === CREATE_STEPS.processing && !isRollForwardComplete) {
      return (
        <Button variant={'contained'} sx={{...styles.createButton, width: '9.3125rem'}} onClick={_onClose} disabled={!_validateInputs()}><Typography style={styles.createButtonLabel}>Close</Typography></Button>
      )
    } else if (createFlow === CREATE_FLOWS.annualRollForward && isRollForwardComplete) {
      return (
        <Button variant={'contained'} sx={{...styles.createButton, width: '9.3125rem'}} onClick={() => navigate(`/documents/${documentId}`)} disabled={!_validateInputs()}><Typography style={styles.createButtonLabel}>Open</Typography></Button>
      )
    } else {
      return (
        <LoadingButton variant={'contained'} sx={{...styles.createButton, width: '9.3125rem'}} onClick={_createDocument} disabled={!_validateInputs()} loading={loading} loadingIndicator={<Loading loading={loading} loadingProps={{color: WHITE_100}} />}>{!loading && <Typography style={styles.createButtonLabel}>Create new report</Typography>}</LoadingButton>
      )
    }
  }

  const _renderCancelButton = () => {
    if (createFlow === CREATE_FLOWS.annualRollForward && step === CREATE_STEPS.processing && !isRollForwardComplete) {
      return <LoadingButton variant={'outlined'} sx={styles.cancelButton} onClick={_deleteDocument} loading={deleteDocumentLoading} loadingIndicator={<Loading loading={deleteDocumentLoading} loadingProps={{color: BLACK_100}} />}><Typography style={styles.cancelButtonLabel}>{!deleteDocumentLoading ? 'Cancel roll forward' : null}</Typography></LoadingButton>;
    }

    return <Button variant={'outlined'} sx={styles.cancelButton} onClick={_onClose}><Typography style={styles.cancelButtonLabel}>Cancel</Typography></Button>;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <Modal open={open} onClose={_onClose}>
      <Box position={'absolute'} sx={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
        <Box width={'31.25rem'} minWidth={'31.25rem'} maxWidth={'31.25rem'} maxHeight={'40.25rem'}>
          <Stack direction={'column'} sx={styles.modal.base}>
            <Stack direction={'row'} paddingY={'1rem'} paddingLeft={'1.5rem'} paddingRight={'1rem'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography sx={styles.modal.title}>{_renderModalTitle()}</Typography>
              <IconButton size={'small'} onClick={_onClose}><CloseIcon size={'1.25rem'} /></IconButton>
            </Stack>
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
            {_renderImportFile()}
            {_renderPreviousDocumentList()}
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
            <Stack direction={'column'} paddingTop={'1.25rem'} paddingBottom={'1.5rem'} paddingX={'1.5rem'} spacing={'1.25rem'} flex={1} justifyContent={'flex-start'}>
              {_renderCreate()}
            </Stack>
            <Divider orientation={'horizontal'} sx={styles.modal.divider} />
            <Stack direction={'row'} paddingY={'1rem'} paddingLeft={'1.5rem'} paddingRight={'1rem'} justifyContent={'flex-end'} alignItems={'center'} spacing={'0.625rem'}>
              {_renderCancelButton()}
              {_renderCTAButton()}
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

CreateDocumentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  previousDocuments: PropTypes.array,
  previousDocumentId: PropTypes.string,
  createFlow: PropTypes.string,
  existingDocumentId: PropTypes.string,
  onRollForwardComplete: PropTypes.func,
}

export default CreateDocumentModal;
