import React from 'react';
import {PropTypes} from 'prop-types';
import _ from 'lodash';
import {TableContainer, Table, TableBody, TableRow, Skeleton, TableCell, TableHead} from '@mui/material';
import {GREY_BORDER, GREY_BACKGROUND_HOVER} from '../../theme';

export const TableLoader = ({numRows = 0, numPeriods = 1}) => {
  return (
    <TableContainer
      sx={{
        borderRadius: 2,
        border: `1px solid ${GREY_BORDER}`,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
      }}
    >
      <Table className='report-table'>
        <TableHead sx={{height: 36}}>
          <TableRow>
            <TableCell sx={{backgroundColor: GREY_BACKGROUND_HOVER}}>
              <Skeleton sx={{marginLeft: 2}} variant={'rounded'} width={_.random(110, 150, false)} />
            </TableCell>
            {
              Array.from({length: numPeriods}).map((_v, idx) => (
              <TableCell key={idx} sx={{backgroundColor: GREY_BACKGROUND_HOVER}} align="right">
                <Skeleton
                  variant={'rounded'}
                  width={_.random(50, 65, false)}
                  sx={{
                    display: 'inline-block',
                    marginRight: 2,
                  }}
                />
              </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Array.from({length: numRows}).map((_v, idx) => (
              <TableRow key={idx}>
                <TableCell>
                  <Skeleton sx={{marginLeft: 2}} variant={'rounded'} width={_.random(110, 150, false)} />
                </TableCell>
                {
                  Array.from({length: numPeriods}).map((_v, idx) => (
                  <TableCell key={idx} align="right">
                    <Skeleton
                      variant={'rounded'}
                      width={_.random(50, 65, false)}
                      sx={{
                        display: 'inline-block',
                        marginRight: 2,
                      }}
                    />
                  </TableCell>
                  ))
                }
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TableLoader.propTypes = {
  numRows: PropTypes.number.isRequired,
  numPeriods: PropTypes.number,
};
