import {styled, Box} from '@mui/material'

export const HomePageCard = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  backgroundColor: 'white',
  borderRadius: '8px',
  border: '1px solid #DCE2E0',
  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
}));
