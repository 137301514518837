import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import LoadingButton from '@mui/lab/LoadingButton';
import {DownloadIcon} from 'lucide-react';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {triggerDownload} from '../util';
import * as api from '../util/api';
import {Checkbox, Divider, FormControl, FormControlLabel, FormGroup, IconButton, Modal, Stack} from '@mui/material';
import {APP_HEADER_HEIGHT} from '../App';
import {WHITE_100} from '../theme';
import CloseIcon from '@mui/icons-material/Close';
import {REPORT_TYPES} from '../constants';
import Button from '@mui/material/Button';

const styles = {
  base: {
    borderRadius: '0.5rem',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    backgroundColor: WHITE_100,
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: '700',
    lineHeight: '120%',
    color: '#043B34',
  },
  divider: {
    borderBottomWidth: '0.0625rem',
    opacity: 1,
    color: '#DDe0DE',
  },
  label: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#043B34',
  },
  downloadButton: {
    background: '#26A993',
    borderRadius: '0.5rem',
    paddingVertical: '0.625rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: '#26A993',
    },
  },
  downloadButtonLabel: {
    fontSize: '0.875rem',
    lineHeight: '140%',
    fontWeight: '500',
  },
  cancelButton: {
    borderRadius: '0.5rem',
    paddingVertical: '0.6825rem',
    paddingHorizontal: '1rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: WHITE_100,
    },
  },
  cancelButtonLabel: {
    color: '#043B34',
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: '130%',
  },
}

const buildDefaultSelectedReports = reports => {
  const _selectedReports = {};
  _.forEach(reports, _report => {
    _selectedReports[_report.reportType] = true;
  });
  return _selectedReports;
}

const DownloadReportComponent = ({reports, visible}) => {
  const {currentOrg} = useSelector((state) => state.session);
  const {selectedPeriod: period, selectedComparePeriod: comparePeriod} = useSelector((state) => state.periods);
  const [downloadingReports, setDownloadingReports] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReports, setSelectedReports] = useState(buildDefaultSelectedReports(reports));

  useEffect(() => {
    setSelectedReports(buildDefaultSelectedReports(reports));
  }, [reports]);

  const downloadReports = async () => {
    setDownloadingReports(true);
    try {
      const _filename = `${_.has(currentOrg, 'org_metadata.companyLegalName') ? currentOrg.org_metadata.companyLegalName : currentOrg.orgName} - ${period}.xlsx`;
      const _params = {period, comparePeriod}
      const _payload = {reportTypes: Object.keys(selectedReports).filter(key => selectedReports[key])};
      await triggerDownload(() => api.downloadReports(_payload, _params), _filename);
      setSelectedReports(buildDefaultSelectedReports(reports));
      onClose();
    } catch (_error) {
      console.error(_error);
    } finally {
      setDownloadingReports(false);
    }
  }

  const handleReportSelection = (reportType) => (event) => {
    setSelectedReports({...selectedReports, [reportType]: event.target.checked});
  }

  const onClose = () => {
    setModalOpen(false);
  }

  if (!visible) {
    return null;
  }

  return (
    <React.Fragment>
      <Modal
        open={modalOpen}
        onClose={onClose}>
        <Box position={'absolute'} sx={{left: '50%', top: `calc(${APP_HEADER_HEIGHT} + 1.75rem)`, transform: 'translate(-50%, 0)'}}>
          <Box width='28rem' minWidth='28rem' maxWidth='28rem'>
            <Stack direction='column' sx={styles.base}>
              <Stack
                direction='row'
                paddingY='1rem'
                paddingLeft='1.5rem'
                paddingRight='1rem'
                flex={1}
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography sx={styles.title}>Select reports to download</Typography>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Divider orientation='horizontal' sx={styles.divider} />
              <Stack
                direction='column'
                sx={{pt: 2, pl: 3, pr: 4}}
              >
                <FormControl component="fieldset" fullWidth>
                  <FormGroup aria-label="position" sx={{flex: 1, display: 'flex', marginBottom: '2.5rem'}}>
                    <FormControlLabel
                      key={'BALANCE_SHEET'}
                      checked={selectedReports.BALANCE_SHEET}
                      onChange={handleReportSelection('BALANCE_SHEET')}
                      value={'BALANCE_SHEET'}
                      control={<Checkbox style={{color: '#26A993', width: 12, height: 12}}/>}
                      label={REPORT_TYPES.BALANCE_SHEET.title}
                      labelPlacement="start"
                      sx={{flex: 1, justifyContent: 'space-between', marginLeft: 0, mb: 1.5}}
                    />
                    <FormControlLabel
                      key={'INCOME_STATEMENT_GAAP'}
                      checked={selectedReports.INCOME_STATEMENT_GAAP}
                      onChange={handleReportSelection('INCOME_STATEMENT_GAAP')}
                      value={'INCOME_STATEMENT_GAAP'}
                      control={<Checkbox style={{color: '#26A993', width: 12, height: 12}}/>}
                      label={REPORT_TYPES.INCOME_STATEMENT_GAAP.title}
                      labelPlacement="start"
                      sx={{flex: 1, justifyContent: 'space-between', marginLeft: 0, my: 1.5}}
                    />
                    <FormControlLabel
                      key={'INCOME_STATEMENT_NON_GAAP'}
                      checked={selectedReports.INCOME_STATEMENT_NON_GAAP}
                      onChange={handleReportSelection('INCOME_STATEMENT_NON_GAAP')}
                      value={'INCOME_STATEMENT_NON_GAAP'}
                      control={<Checkbox style={{color: '#26A993', width: 12, height: 12}}/>}
                      label={REPORT_TYPES.INCOME_STATEMENT_NON_GAAP.title}
                      labelPlacement="start"
                      sx={{flex: 1, justifyContent: 'space-between', marginLeft: 0, mt: 1.5}}
                    />
                    {/*
                    <FormControlLabel
                      key={'INDIRECT_CASHFLOW'}
                      checked={selectedReports.INDIRECT_CASHFLOW}
                      onChange={handleReportSelection('INDIRECT_CASHFLOW')}
                      value={'INDIRECT_CASHFLOW'}
                      control={<Checkbox style={{color: '#26A993', width: 12, height: 12}}/>}
                      label={REPORT_TYPES.INDIRECT_CASHFLOW.title}
                      labelPlacement="start"
                      sx={{flex: 1, justifyContent: 'space-between', marginLeft: 0, my: 1.5}}
                    />
                    <FormControlLabel
                      key={'DIRECT_CASHFLOW'}
                      checked={selectedReports.DIRECT_CASHFLOW}
                      onChange={handleReportSelection('DIRECT_CASHFLOW')}
                      value={'DIRECT_CASHFLOW'}
                      control={<Checkbox style={{color: '#26A993', width: 12, height: 12}}/>}
                      label={REPORT_TYPES.DIRECT_CASHFLOW.title}
                      labelPlacement="start"
                      sx={{flex: 1, justifyContent: 'space-between', marginLeft: 0, my: 1.5}}
                    />
                    */}
                  </FormGroup>
                </FormControl>
              </Stack>
              <Divider orientation='horizontal' sx={styles.divider} />
              <Stack
                direction='row'
                paddingY='1rem'
                paddingLeft='1.5rem'
                paddingRight='1rem'
                flex={1}
                justifyContent='flex-end'
                alignItems='center'
                spacing='0.5rem'
              >
                <Button
                  variant='outlined'
                  sx={styles.cancelButton}
                  onClick={onClose}
                >
                  <Typography style={styles.cancelButtonLabel}>Cancel</Typography>
                </Button>
                <LoadingButton
                  disabled={_.every(_.values(selectedReports), _i => !_i)}
                  variant='contained'
                  sx={{...styles.downloadButton}}
                  onClick={() => downloadReports()}
                  loading={downloadingReports}
                >
                  <Typography style={styles.downloadButtonLabel}>Download</Typography>
                </LoadingButton>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Modal>

      <Button
        onClick={() => setModalOpen(true)}
        startIcon={<DownloadIcon size={16} />}
        disabled={!visible}
        variant="contained"
        color="green"
        sx={{borderRadius: '8px', padding: '6px 16px'}}
      >
        Download
      </Button>
    </React.Fragment>
  )
}

DownloadReportComponent.propTypes = {
  visible: PropTypes.bool.isRequired,
  reports: PropTypes.array,
}

export default withLDConsumer()(DownloadReportComponent);
