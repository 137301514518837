import React, {Component} from 'react';
import Error from '../Error';
import NoData from '../NoData';
import PropTypes from 'prop-types';
import {Box, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import ReportDetailsModal from './ConfiguredReportDetailsModal';
import {connect} from 'react-redux';
import {withLogger} from '../LoggingWrapper';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import {triggerDownload, renderAmount, trackEvent} from '../../util';
import * as api from '../../util/api';
import {withRequiredAuthInfo} from '@propelauth/react';
import ReportTable from '../ReportTable';
import {GREY_90, GREY_100} from '../../App';
import TableRow from '@mui/material/TableRow';
import TableCell from '../TableCell';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import Decimal from 'decimal.js-light';
import {setHasDifference} from '../../data/reports';
import {TableLoader} from './TableLoader';

const INITIAL_PLI_DETAILS = {
  pli: {},
  open: false,
}

export const enableHover = (row, report, flag, scfDrillDownFlag) => {
  if (!_.has(report, 'reportType')) {
    return false;
  }
  if (!scfDrillDownFlag && report.reportType === 'DIRECT_CASHFLOW') {
    return false;
  }
  return (
    flag &&
    _.includes(['BALANCE_SHEET', 'INCOME_STATEMENT_GAAP', 'INCOME_STATEMENT_NON_GAAP', 'DIRECT_CASHFLOW'], report.reportType) &&
    (row.children.length === 0 || _.sumBy(row.children, _c => _c.config.hideLine ? 0 : 1) === 0) &&
    ((!_.isNil(row.amount) && row.amount !== 0) || (!_.isNil(row.compareAmount) && row.compareAmount !== 0))
  );
}

export class ConfiguredReportClass extends Component {
  constructor (props) {
    super(props);

    this.index = 0;

    this.state = {
      pliDetails: INITIAL_PLI_DETAILS,
      loadingTemplate: false,
    }
  }

  downloadFile = async _reportId => {
    const _startTime = new Date().getTime();
    this.setState({loadingTemplate: true});
    try {
      await triggerDownload(() => api.getFile(_reportId), `${this.props.title} - ${this.props.period}.xlsx`);
      trackEvent('Download Report Completed', {reportId: _reportId}, new Date().getTime() - _startTime);
    } catch (_error) {
      console.error(_error);
      trackEvent('Download Report Failed', {reportId: _reportId}, new Date().getTime() - _startTime);
    } finally {
      this.setState({loadingTemplate: false});
    }
  }

  // SCF Specific Download
  // Needs updating
  renderDownloadButton = () => {
    if (this.props.report?.filledTemplateExcelKey && (!_.has(this.props.currentOrg, 'org_metadata.hideDownload') || this.props.currentOrg.org_metadata.hideDownload === false)) {
      return (
        <LoadingButton
          // onClick={() => this.downloadFile(this.props.report.record.fileId)}
          endIcon={<DownloadIcon />}
          variant="contained"
          color={'darkGreen'}
          loading={this.state.loadingTemplate}
        >
          Download Workbook
        </LoadingButton>
      );
    }
  }

  onClose = () => {
    this.setState({pliDetails: INITIAL_PLI_DETAILS});
  }

  openPliDetails = (row) => {
    if (enableHover(row, this.props.report, this.props.flags.reportDetails, this.props.flags.reportDrillDownsScf)) {
      this.setState({pliDetails: {pli: row, open: true}});
    }
  }

  _getComparePeriod = (period) => {
    if (!_.isNil(this.props.comparePeriod) && !_.isEmpty(this.props.comparePeriod)) {
      return {
        comparePeriod: this.props.reportingPeriodMap[this.props.comparePeriod],
      };
    }

    return {
      comparePeriod: null,
    }
  }

  renderAmountCell = (amount, showCurrency, invertDisplayAmount, fontStyle) => {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1" {...fontStyle}>
          {showCurrency && '$'}
        </Typography>
        <Typography variant="body1" {...fontStyle}>{renderAmount(amount, invertDisplayAmount)}</Typography>
      </Stack>
    )
  }

  renderRow = (row) => {
    if (row.config.hideLine || (new Decimal(row.amount).todp(0).toNumber() === 0 && !_.has(row, 'compareAmount')) || (new Decimal(row.amount).todp(0).toNumber() === 0 && _.has(row, 'compareAmount') && new Decimal(row.compareAmount).todp(0).toNumber() === 0)) {
      return null;
    }
    this.index += 1;
    const _borderStyle = {};
    if (row.config.showSingleLineHeader) {
      _borderStyle.borderTop = `1px solid ${GREY_90}`;
    }
    if (row.config.showSingleLineFooter) {
      _borderStyle.borderBottom = `1px solid ${GREY_90}`;
    } else if (row.config.showDoubleLineFooter) {
      _borderStyle.borderBottom = `3px double ${GREY_90}`;
    }

    const _fontStyle = {};
    if (row.config.isBold) {
      _fontStyle.fontWeight = '700';
    }
    if (_.has(row, 'compareAmount') && !_.isNil(row.compareAmount) && new Decimal(row.compareAmount).todp(0).toNumber() !== new Decimal(row.amount).todp(0).toNumber()) {
      this.props.setHasDifference({hasDifference: true});
    }
    return (
      <TableRow
        key={row._locatorId}
        hover={enableHover(row, this.props.report, this.props.flags.reportDetails, this.props.flags.reportDrillDownsScf)}
        sx={{
          '&.MuiTableRow-hover:hover .MuiTableCell-root': {
            cursor: 'pointer',
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: GREY_100,
          },
        }}
        onClick={() => this.openPliDetails(row)}
      >
        <TableCell
          sx={{width: '100%', paddingLeft: `calc(1.5rem + ${row.indent}rem) !important`}}
          scope="row"
        >
          <Typography variant="body1" {..._fontStyle}>{row.name}</Typography>
        </TableCell>
        {!row.config.isHeaderLine && (
          <>
            <TableCell align="right" scope="row">
              <Box sx={_borderStyle}>
                {this.renderAmountCell(row.amount, row.config.showCurrency, row.config.invertDisplayAmount, _fontStyle)}
              </Box>
            </TableCell>
            {(!_.isNil(this.props.comparePeriod) && !_.isEmpty(this.props.comparePeriod)) && (
              <TableCell align="right" scope="row">
                <Box sx={_borderStyle}>
                  {this.renderAmountCell(row.compareAmount, row.config.showCurrency, row.config.invertDisplayAmount, _fontStyle)}
                </Box>
              </TableCell>
            )}
          </>
        )}
        {row.config.isHeaderLine && (
          <>
            <TableCell align="right" scope="row"/>
            {(!_.isNil(this.props.comparePeriod) && !_.isEmpty(this.props.comparePeriod)) && (
              <TableCell align="right" scope="row" />
            )}
          </>
        )}
      </TableRow>
    )
  }

  renderRows = (rows) => {
    return _.map(rows, _row => {
      if (_row.config.hideLine && _row.children.length > 0) {
        return this.renderRows(_row.children);
      } else if (_row.config.isHeaderLine) {
        return (
          <React.Fragment key={_row._locatorId}>
            {this.renderRow(_row)}
            {this.renderRows(_row.children)}
          </React.Fragment>
        );
      } else if (!_row.config.isHeaderLine) {
        return (
          <React.Fragment key={_row._locatorId}>
            {this.renderRows(_row.children)}
            {this.renderRow(_row)}
          </React.Fragment>
        );
      } else {
        return null;
      }
    })
  }

  renderReport = (visible = true) => {
    if (!visible || this.props.hasError) {
      return null;
    }

    if (this.props.loading) {
      const numPeriods = this.props.comparePeriod ? 2 : 1;
      return <TableLoader numRows={15} numPeriods={numPeriods} />;
    }

    if (_.isNil(this.props.report) || _.isNil(this.props.report.reportConfig)) {
      return;
    }

    const _period = this.props.reportingPeriodMap[this.props.period];
    const {comparePeriod: _comparePeriod} = this._getComparePeriod(_period);
    const _meta = _.get(this.props.report, 'reportFile.metadata', null);

    return (
      <Box width={'100%'}>
        <Stack direction={'column'} flex={1}>
          <Stack alignItems={'center'} direction={'column'}>
            {this.renderDownloadButton()}
          </Stack>
          <ReportTable period={_period} comparePeriod={_comparePeriod} report={this.props.report} meta={_meta}>
            {this.renderRows(this.props.report.reportConfig, 0)}
          </ReportTable>
        </Stack>
        {_period &&
          <ReportDetailsModal
            pli={this.state.pliDetails.pli}
            open={this.state.pliDetails.open}
            onClose={this.onClose}
            reportType={this.props.report.reportType}
            period={_period}
            comparePeriod={_comparePeriod}
            currentFileId={'replace-me-1'}
            compareFileId={'replace-me'}
            meta={_meta}
          />
        }
      </Box>
    )
  }

  renderReconReport = (visible = true) => {
    if (!visible || _.isNil(this.props.report) || _.isNil(this.props.report.reconReportConfig) || this.props.loading || this.props.hasError) {
      return;
    }

    const _reconReportConfig = this.props.report.reconReportConfig;
    const _reportData = [
      {
        name: `GAAP ${_reconReportConfig.gaapTotal.name}`,
        amount: _reconReportConfig.gaapTotal.amount,
        compareAmount: _reconReportConfig.gaapTotal.compareAmount,
      },
      ..._reconReportConfig.reconDetails,
      {
        name: `Non-GAAP ${_reconReportConfig.nonGaapTotal.name}`,
        amount: _reconReportConfig.nonGaapTotal.amount,
        compareAmount: _reconReportConfig.nonGaapTotal.compareAmount,
      },
    ]
    const _period = this.props.reportingPeriodMap[this.props.period];
    const {comparePeriod: _comparePeriod} = this._getComparePeriod(_period);

    return (
      <>
        <Typography variant={'h3'} component={'div'} textAlign={'center'} marginTop={'1.5rem'} marginBottom={'1.5rem'}>
          {_reconReportConfig.title}
        </Typography>
        <ReportTable
          report={this.props.report}
          period={_period}
          comparePeriod={_comparePeriod}
        >
          {_.map(_reportData, (_data, _idx) => {
            const _borderStyle = {};
            if (_idx === _reportData.length - 1) {
              _borderStyle.borderTop = '1px solid black';
              _borderStyle.borderBottom = '3px double black';
            }

            return (
              <TableRow key={_idx}>
                <TableCell
                  scope="row"
                  sx={{
                    width: '100%',
                    paddingLeft: `${_.inRange(_idx, 1, _reportData.length - 1) ? 3.25 : 2.25}rem !important`,
                  }}
                >
                  <Typography variant={'body1'}>{_data.name}</Typography>
                </TableCell>
                <TableCell align="right" scope="row">
                  <Box sx={_borderStyle}>
                    {this.renderAmountCell(_data.amount, _idx === 0 || _idx === _reportData.length - 1, false)}
                  </Box>
                </TableCell>
                {_comparePeriod && (
                  <TableCell align="right" scope="row">
                    <Box sx={_borderStyle}>
                      {this.renderAmountCell(_data.compareAmount, _idx === 0 || _idx === _reportData.length - 1, false)}
                    </Box>
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </ReportTable>
      </>
    );
  }

  renderSupplementalDisclosures = () => {
    if (!this.props.flags.supplementalDisclosuresReport || !_.has(this.props, 'report.supplementalDisclosures') || this.props.loading || this.props.hasError) {
      return;
    }

    const _supplementalDisclosuresConfig = this.props.report.supplementalDisclosures;
    const _reportData = _supplementalDisclosuresConfig.disclosureDetails;

    const _period = this.props.reportingPeriodMap[this.props.period];
    const {comparePeriod: _comparePeriod} = this._getComparePeriod(_period);

    return (
      <React.Fragment>
        <Typography variant={'h3'} component={'div'} textAlign={'center'} marginTop={'1.5rem'}>
          {_supplementalDisclosuresConfig.title}
        </Typography>
        <ReportTable period={_period} comparePeriod={_comparePeriod}>
          {_.map(_reportData, (_data, _idx) => {
            const _style = {};
            return (
              <TableRow
                key={_idx}
              >
                <TableCell sx={{width: '100%', paddingLeft: `${_.inRange(_idx, 1, _reportData.length - 1) ? 3.25 : 2.25}rem`}} scope="row">
                  <Typography variant={'body1'}>{_data.name}</Typography>
                </TableCell>
                <TableCell align="right" sx={{..._style, minWidth: '15rem', maxWidth: '15rem'}} scope="row" >
                  {this.renderAmountCell(_data.amount, _idx === 0 || _idx === _reportData.length - 1, _.get(_data, 'config.invertDisplayAmount', false))}
                </TableCell>
                {_comparePeriod && (
                  <TableCell align="right" sx={{..._style, minWidth: '15rem', maxWidth: '15rem'}} scope="row" >
                    {this.renderAmountCell(_data.compareAmount, _idx === 0 || _idx === _reportData.length - 1, _.get(_data, 'config.invertDisplayAmount', false))}
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </ReportTable>
      </React.Fragment>
    );
  }

  render = () => {
    if (!this.props.visible) {
      return;
    }

    const _period = this.props.reportingPeriodMap[this.props.period];
    const _periodType = !_.isNil(_period) ? _period.periodType : null;
    const hasData = !_.isNil(this.props.report) && !_.isNil(this.props.report.reportType) && !_.isNil(_period);

    return (
      <Stack direction="column">
        <Error
          hasError={this.props.hasError}
          message="There was an error processing your request. Please try again later."
        />
        <NoData
          hidden={this.props.loading || this.props.hasError || hasData}
          screen={this.props.title}
          reportType={this.props.report?.reportType}
          periodType={_periodType}
        />
        {this.renderReport()}
        {this.renderReconReport()}
        {this.renderSupplementalDisclosures()}
      </Stack>
    )
  }
}

ConfiguredReportClass.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  hasError: PropTypes.bool.isRequired,
  reportingPeriodMap: PropTypes.object.isRequired,
  currentOrg: PropTypes.shape({
    orgId: PropTypes.string.isRequired,
    org_metadata: PropTypes.shape({
      companyLegalName: PropTypes.string,
      hideDownload: PropTypes.bool,
    }),
    orgName: PropTypes.string.isRequired,
  }),
  comparePeriod: PropTypes.string,
  accessToken: PropTypes.string.isRequired,
  period: PropTypes.string,
  report: PropTypes.shape({
    reportType: PropTypes.string,
    reportConfig: PropTypes.array,
    reportFile: PropTypes.array,
    reconReportConfig: PropTypes.object,
    supplementalDisclosures: PropTypes.object,
    filledTemplateExcelKey: PropTypes.string,
  }),
  flags: PropTypes.shape({
    reportDetails: PropTypes.bool.isRequired,
    reportDrillDownsScf: PropTypes.bool.isRequired,
    supplementalDisclosuresReport: PropTypes.bool.isRequired,
  }).isRequired,
  setHasDifference: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    currentOrg: state.session.currentOrg,
    reportingPeriodMap: state.periods.reportingPeriodMap,
  }
}

export default withLDConsumer()(connect(mapStateToProps, {setHasDifference})(withRequiredAuthInfo(withLogger(ConfiguredReportClass))));
