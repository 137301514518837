import React, {useState} from 'react';
import _ from 'lodash';
import {GREY_80} from '../App';
import TableCell from './TableCell';
import {
  Table, TableHead, TableSortLabel, TableRow, TableContainer, TableBody,
  Paper,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import {visuallyHidden} from '@mui/utils';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TableHeaderDateCell from './financialStatements/TableHeaderDateCell';
import {GREY_BORDER} from '../theme';

const tableHeaderCellStyles = {
  width: '184px',
  height: 38,
};

const tableInnerHeaderCellStyles = {
  borderBottom: '2px solid black!important',
  marginLeft: '20px!important',
  justifyContent: 'center',
  ...tableHeaderCellStyles,
};

const DetailTable = ({order, orderBy, handleRequestSort, renderTableBody, tableHeaders, period, comparePeriod, reportType, generatedPeriod, hasHeader = true, collapsable = false, expandAll}) => {
  const [open, isOpen] = useState(false);

  return (
    <TableContainer
      component={Paper}
      sx={{
        border: `1px solid ${GREY_BORDER}`,
        boxShadow: 'none',
        borderRadius: 0,
        paddingBottom: 2,
        maxHeight: 'calc(100vh - 18rem)',
      }}>
      <Table stickyHeader className='report-table'>
        {hasHeader && (
          <TableHead sx={{position: 'sticky', top: '0', zIndex: 1}}>
            <TableRow align="right">
              <TableCell variant="head" className="header-row-cell" colSpan={tableHeaders.length} />
              <TableCell
                align="right"
                variant="head"
                className="header-row-cell"
                sx={tableHeaderCellStyles}
                sortDirection={orderBy === 'periodEndDate' ? order : false}
              >
                <TableSortLabel
                  sx={tableInnerHeaderCellStyles}
                  className="header-row-cell"
                  active={orderBy === 'amount'}
                  direction={orderBy === 'amount' ? order : 'asc'}
                  onClick={handleRequestSort('amount')}>
                  <TableHeaderDateCell
                    period={period}
                    generatedPeriod={generatedPeriod}
                    reportType={reportType}
                    minWidth={'max-content'}
                  />
                  {orderBy === 'amount'
                    ? (
                      <Box component={'span'} sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                      )
                    : null}
                </TableSortLabel>
              </TableCell>
              {!_.isNil(comparePeriod) && !_.isEmpty(comparePeriod.endDate) && (
                <TableCell
                  align="right"
                  variant="head"
                  className="header-row-cell"
                  sx={tableHeaderCellStyles}
                  sortDirection={orderBy === 'compareTotal' ? order : false}
                >
                  <TableSortLabel
                    sx={tableInnerHeaderCellStyles}
                    active={orderBy === 'compareTotal'}
                    direction={orderBy === 'compareTotal' ? order : 'asc'}
                    onClick={handleRequestSort('compareTotal')}>
                    <TableHeaderDateCell
                      period={comparePeriod}
                      generatedPeriod={generatedPeriod}
                      reportType={reportType}
                      minWidth="max-content"
                    />
                    {
                      orderBy === 'compareTotal'
                        ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                          )
                        : null
                    }
                  </TableSortLabel>
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              {
                _.map(tableHeaders, _header => {
                  const _sx = {
                    borderBottom: 1,
                    borderColor: GREY_80,
                    paddingLeft: collapsable ? 0 : '2.5rem',
                    verticalAlign: 'bottom',
                  };
                  if (_.has(_header, 'width')) {
                    _sx.width = _header.width;
                  }
                  return (
                    <TableCell
                      key={_header.id}
                      variant="head"
                      align="left"
                      className="header-row-cell"
                      sx={{padding: '0.5rem!important'}}
                      sortDirection={orderBy === _header.id ? order : false}
                    >
                      {collapsable && (
                        <IconButton
                          size="small"
                          sx={{marginRight: 1}}
                          onClick={() => {
                            isOpen(!open);
                            expandAll();
                          }}
                        >
                          {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                        </IconButton>
                      )}
                      <TableSortLabel
                        active={orderBy === _header.id}
                        direction={orderBy === _header.id ? order : 'asc'}
                        onClick={handleRequestSort(_header.id)}>
                        <Typography
                          variant="body2"
                          fontWeight="600"
                          sx={{
                            ...(!collapsable && {
                              marginLeft: 3,
                            }),
                          }}
                        >
                          {_header.label}
                        </Typography>
                        {orderBy === _header.id
                          ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                            )
                          : null}
                      </TableSortLabel>
                    </TableCell>
                  )
                })
              }
              {[period, comparePeriod].filter(Boolean).map((v) => (
                <TableCell
                  key={`${v}-amount-header`}
                  variant="head"
                  align="right"
                  className="header-row-cell"
                >
                  <Box>
                    {reportType !== 'BALANCE_SHEET' && <Typography>Amount</Typography>}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {renderTableBody()}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

DetailTable.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  handleRequestSort: PropTypes.func.isRequired,
  renderTableBody: PropTypes.func.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  generatedPeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  period: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }).isRequired,
  comparePeriod: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    periodType: PropTypes.string.isRequired,
  }),
  reportType: PropTypes.string,
  hasHeader: PropTypes.bool,
  collapsable: PropTypes.bool,
  expandAll: PropTypes.func,
};

export default DetailTable;
