/**
 * @typedef {object} MonthOption
 * @property {string} shortLabel - Abbreviated month name (e.g., "Jan").
 * @property {string} label - Full month name (e.g., "January").
 * @property {string} value - Month value (e.g., "01").
 *
 * A list of the months and some metadata, using a 1-based index for API
 *
 * @type {MonthOption[]}
 */
export const MONTH_OPTIONS = [
  {shortLabel: 'Jan', label: 'January', value: '01'},
  {shortLabel: 'Feb', label: 'February', value: '02'},
  {shortLabel: 'Mar', label: 'March', value: '03'},
  {shortLabel: 'Apr', label: 'April', value: '04'},
  {shortLabel: 'May', label: 'May', value: '05'},
  {shortLabel: 'Jun', label: 'June', value: '06'},
  {shortLabel: 'Jul', label: 'July', value: '07'},
  {shortLabel: 'Aug', label: 'August', value: '08'},
  {shortLabel: 'Sep', label: 'September', value: '09'},
  {shortLabel: 'Oct', label: 'October', value: '10'},
  {shortLabel: 'Nov', label: 'November', value: '11'},
  {shortLabel: 'Dec', label: 'December', value: '12'},
];

export const subtractMonth = (date, amount) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() - amount);
  return newDate;
}

/**
 * Returns the current month as a 1-based index.
 *
 * @export
 * @returns {number} The current month (1 for January, 2 for February, ..., 12 for December).
 */
export const getCurrentMonth = () => new Date().getMonth() + 1;

/**
 * Returns the current year.
 *
 * @export
 * @returns {number} The current year (e.g., 2025).
 */
export const getCurrentYear = () => new Date().getFullYear();

/**
 * Returns the current fiscal year based on the fiscal end month
 *
 * @param {number} fiscalEndMonth - the fiscal month as a 1-based index
 * @returns
 */
export const getCurrentFiscalYear = (fiscalEndMonth) => getCurrentYear() + Number(fiscalEndMonth < getCurrentMonth());

/**
 * Calculates the current quarter based on the provided month and the fiscal year end month.
 *
 * 1. Convert the 1-based month index (where January is 1 and December is 12) to a 0-based index (where January
 *    is 0 and December is 11) by subtracting 1. This is necessary for using modulo arithmetic to wrap around.
 *
 * 2. Adding 12 to this value helps to handle cases where the fiscal year end month is less than the current month,
 *    ensuring that the calculation wraps around correctly.
 *
 * 3. Subtracting the `fiscalEndMonth` from this adjusted value aligns the month index with the fiscal year, allowing
 *    for accurate quarter determination based on the fiscal calendar.
 *
 * 4. Using the modulo operation `% 12` ensures that the result remains within the valid range of months, effectively
 *    wrapping around if the value exceeds 12.
 *
 * 5. Finally, adding 1 converts the 0-based index back to a 1-based index, which fits our current model in the app.
 *
 * The `currentQuarter` is then calculated by dividing the `adjustedMonth` by 3. This step groups the months into
 * quarters, allowing for a straightforward identification of which quarter the month falls into.
 *
 * @param {number} month - The current month as a 1-based index (1 for January, 12 for December).
 * @param {number} fiscalEndMonth - The fiscal month as a 1-based index indicating the end of the fiscal year.
 * @returns {number} The current quarter (1-4) of the fiscal year.
 */
export const getQuarter = (month, fiscalEndMonth) => {
  const adjustedMonth = (month - 1 + 12 - fiscalEndMonth) % 12 + 1;
  return Math.ceil(adjustedMonth / 3);
}

/**
 * Converts a year and month (1-based index) numbers to the ISO8601 month representation.
 * toMonthISO(2025, 1) -> '2025-01' // February, 2025
 * toMonthISO(2022, 7) -> '2022-07' // July, 2022
 *
 * @param {number} year - the integer representation of the year
 * @param {number} month - the 1-based index integer representation of the month. 1 = January
 * @returns {string}
 */
export const toMonthISO = (year, month) => `${year}-${String(month).padStart(2, '0')}`;

/**
 * Compares two ISO 8601 month strings (YYYY-MM) to determine their relative order.
 *
 * @export
 * @param {string} date1 - The first date string in "YYYY-MM" format.
 * @param {string} date2 - The second date string in "YYYY-MM" format.
 * @returns {-1 | 0 | 1 | string} Returns -1 if date1 is earlier, 1 if date2 is earlier, 0 if the dates are the same, or an error message if the input format is invalid.
 *
 * @example
 * compareIso8601Months("2023-05", "2023-08"); // Returns -1
 * compareIso8601Months("2024-01", "2023-12"); // Returns 1
 * compareIso8601Months("2023-05", "2023-05"); // Returns 0
 * compareIso8601Months("2023/05", "2023-08"); // Returns "Invalid date format. Expected "YYYY-MM"."
 */
export function compareIso8601Months (date1, date2) {
  const regex = /^\d{4}-\d{2}$/;

  if (!regex.test(date1) || !regex.test(date2)) {
    return 'Invalid date format. Expected "YYYY-MM".';
  }

  const num1 = parseInt(date1.replace('-', ''));
  const num2 = parseInt(date2.replace('-', ''));

  if (num1 < num2) {
    return -1;
  } else if (num1 > num2) {
    return 1;
  }

  return 0;
}

/**
 * Generates an array of 12 month options, starting from a given month index (1-based)
 * and wrapping around the year.
 *
 * @export
 * @param {number} startMonth - The starting month (e.g., 1 [January], 3 [March], 12 [December]).
 * @returns {MonthOption[]} An array of 12 months rotated to start with the given month (1-based index)
 *
 * @example
 * getRotatedCalendar(1); // Returns [{ shortLabel: "Jan", ...}, { shortLabel: "Feb", ...}, ..., { shortLabel: "Dec", ...}]
 * getRotatedCalendar(3); // Returns [{ shortLabel: "Mar", ...}, { shortLabel: "Apr", ...}, ..., { shortLabel: "Feb", ...}]
 * getRotatedCalendar(12); // Returns [{ shortLabel: "Dec", ...}, { shortLabel: "Jan", ...}, ..., { shortLabel: "Nov", ...}]
 */
export function getRotatedCalendar (startMonthValue) {
  const startIndex = startMonthValue - 1;
  if (startIndex < 0 || startIndex > 12) {
    console.error('The provided start month wasn\'t a 1-based number index.')
    return [];
  } else if (startIndex === 0) {
    return MONTH_OPTIONS;
  }

  return MONTH_OPTIONS.map((_, i, arr) => arr[(startIndex + i) % 12]);
}

/**
 * Computes the fiscal year start month (1-indexed) given a fiscal year end date formatted as "MM-DD"
 * The fiscal year starts the next month following the end month, wrapping around the calendar as needed.
 *
 * @export
 * @param {string} fiscalYearEnd - A date string in the format "MM-DD"
 * @returns {number} The 1-based index of the fiscal year start month.
 */
export function getFiscalYearStartMonth (fiscalYearEnd) {
  // Split the "MM-DD" string into month and day components.
  const [endMonth] = fiscalYearEnd.split('-').map(Number);

  // The fiscal year starts on the following month. Use modulo arithmetic to wrap December to January.
  return (endMonth % 12) + 1;
}

/**
 * Formats a psuedo ISO8601 month string into the expected format for UI display.
 *
 * @export
 * @param {string} dateString - The psuedo ISO8601 month string can be valid, YYYY-FY, YYYY-Q<1-4>
 * @returns {string} - The string formattted for UI display
 */
export function formatDate (dateString) {
  // Psuedo ISO8601, four digits followed by a hyphen, then two of any character
  if (/^\d{4}-.{2}$/.test(dateString)) {
    const [left, right] = dateString.split('-');

    // Valid ISO8601 month format
    if (!isNaN(Number(right))) {
      const foundMonth = MONTH_OPTIONS.find(({value}) => value === right);
      if (foundMonth) {
        return `${foundMonth.shortLabel} ${left}`;
      }
    } else {
      // FY (Fiscal year) or Q<number> (Quarter) selected
      return `${right} ${left}`;
    }
  }

  return dateString;
}

/**
 * Returns the months and quarters for a given fiscal year in the order expected for the fiscal start with a disabled
 * flag for the UI based on min/max calendar dates.
 *
 * @export
 * @param {number} fiscalYear - The fiscal year selected by the user (the year the fiscal period ends).
 * @param {number} fiscalMonthStart - The month (1-12) that the fiscal year starts.
 * @param {string} minCalendarDate - The earliest available data (e.g., '2022-02' for February 2022).
 * @returns {{ disabledMonths: string[], disabledQuarters: string[] }} - The list of months and quarters with metadata.
 */
export function getUIDates (fiscalYear, fiscalMonthStart, minCalendarDate) {
  const currentISO = toMonthISO(getCurrentYear(), getCurrentMonth());

  // Create the fiscal calendar starting from the fiscalYearStartMonth.
  const months = getRotatedCalendar(fiscalMonthStart);
  const quarters = [
    {label: 'Quarter 1', value: 'Q1', disabled: true},
    {label: 'Quarter 2', value: 'Q2', disabled: true},
    {label: 'Quarter 3', value: 'Q3', disabled: true},
    {label: 'Quarter 4', value: 'Q4', disabled: true},
  ];

  const JanuaryIndex = months.findIndex(({value}) => value === '01');
  for (let i = 0; i < months.length; i++) {
    const calendarYear = fiscalYear - Number(i < JanuaryIndex);

    months[i].calendarYear = calendarYear;

    const monthIso = toMonthISO(calendarYear, months[i].value);

    if (compareIso8601Months(monthIso, minCalendarDate) < 0 || compareIso8601Months(monthIso, currentISO) > 0) {
      months[i].disabled = true;
    } else {
      months[i].disabled = false;
    }

    // If any month in the quarter is enabled, then the quarter is not fully disabled.
    if (!months[i].disabled) {
      quarters[Math.floor(i / 3)].disabled = false
    }
  }

  return {months, quarters};
}
