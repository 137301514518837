import React from 'react'
import PropTypes from 'prop-types';
import {Box, ListItemIcon, ListItemText as MuiListItemText, Menu as MuiMenu, MenuItem as MuiMenuItem} from '@mui/material';
import {bindMenu, bindPopper, bindHover} from 'material-ui-popup-state';
import {usePopupState} from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

import {GREY_BORDER} from '../../theme';

const styles = {
  menu: {
    base: {
      marginTop: 1,
      '.MuiList-root': {
        padding: 0,
      },
      '.MuiPaper-root': {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        border: `1px solid ${GREY_BORDER}`,
        boxShadow: '0 2px 6px 0 rgba(15, 30, 36, 0.1)',
        borderRadius: '8px',
      },
    },
    popover: {
      '.MuiPaper-root': {
        padding: '8px',
        borderRadius: '8px',
        border: `1px solid ${GREY_BORDER}`,
        boxShadow: '0 2px 6px 0 rgba(15, 30, 36, 0.1)',
        marginLeft: '16px',
        marginTop: '-8px',
        minWidth: '128px',
      },
    },
    listText: {
      fontSize: '14px',
    },
    item: {
      borderRadius: '8px',
      height: '36px',
    },
  },
}

export const CascadingMenu = ({popupState, ...props}) => {
  const {rootPopupState} = React.useContext(CascadingContext)
  const context = React.useMemo(() => ({
    rootPopupState: rootPopupState || popupState,
    parentPopupState: popupState,
  }), [rootPopupState, popupState]);

  return (
    <CascadingContext.Provider value={context}>
      <Menu {...props} {...bindMenu(popupState)} sx={{width: props.width}} />
    </CascadingContext.Provider>
  )
}

CascadingMenu.propTypes = {
  width: PropTypes.string,
  popupState: PropTypes.object.isRequired,
}

export const CascadingSubmenu = ({children, id, leftIcon, rightIcon, listItemText, ...props}) => {
  const {parentPopupState} = React.useContext(CascadingContext)
  const popupState = usePopupState({variant: 'popover', popupId: id, parentPopupState});

  return (
    <MenuItem
      {...props}
      {...bindHover(popupState)}
    >
      {leftIcon && <ListItemIcon>{leftIcon}</ListItemIcon>}
      <ListItemText primaryTypographyProps={styles.menu.listText.primary}>{listItemText}</ListItemText>
      {rightIcon && <ListItemIcon sx={{justifyContent: 'flex-end'}}>{rightIcon}</ListItemIcon>}
      {!rightIcon && <Box width={'2rem'} />}
      <HoverPopover
          {...bindPopper(popupState)}
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          transformOrigin={{vertical: 'top', horizontal: 'left'}}
          sx={styles.menu.popover}
      >
        {children}
      </HoverPopover>
    </MenuItem>
  )
}

CascadingSubmenu.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  listItemText: PropTypes.string.isRequired,
  width: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
}

const CascadingContext = React.createContext({
  parentPopupState: null,
  rootPopupState: null,
});

export const MenuItem = (props) => <MuiMenuItem sx={styles.menu.item} {...props} />;

export const Menu = (props) => <MuiMenu {...props} sx={{
  ...styles.menu.base,
  '.MuiPaper-root': {
    ...styles.menu.base['.MuiPaper-root'],
    width: props.sx.width,
  },
}} />;

Menu.propTypes = {
  sx: PropTypes.object,
};

export const ListItemText = (props) => <MuiListItemText primaryTypographyProps={styles.menu.listText.primary} {...props} />;
