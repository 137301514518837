import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {XIcon, MenuIcon} from 'lucide-react';
import {usePopupState} from 'material-ui-popup-state/hooks';
import {IconButton as MuiIconButton, Typography, Grid, Box} from '@mui/material';
import {bindTrigger} from 'material-ui-popup-state'
import Styled from '@emotion/styled';
import {CascadingMenu, MenuItem} from '../../../components/CascadingMenu/CascadingMenu';
import {GREY_SUBTLE, GREY_BORDER} from '../../../theme';
import {toMonthISO, subtractMonth, getCurrentMonth, getQuarter, getCurrentFiscalYear} from '../../../util/date';

const IconButton = Styled(MuiIconButton)`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 1px solid ${GREY_BORDER};
  width: 44px;
  height: 100%;
`;

/**
   * Computes the quarter options for the preset comparisons.
   *
   * @param {number} month - the month as a 1-based index
   * @param {number} fiscalEndMonth - the end month for the fiscal year as a 1-based number
   * @returns
   */
const getQuarterInfo = (month, fiscalEndMonth) => {
  const currentFiscalYear = getCurrentFiscalYear(fiscalEndMonth);
  const currentQuarter = getQuarter(month, fiscalEndMonth);

  // subtract a year if we are going from quarter 1 to 4
  const lastQuarterYear = currentFiscalYear - Number(currentQuarter === 1);
  // subtract a quarter and wrap around to 4 if 0
  const lastQuarter = (currentQuarter - 1 + 4) % 4 || 4;

  // subtract a year if we are going from quarter 1 to 4
  const priorQuarterYear = lastQuarterYear - Number(lastQuarter === 1);
  // subtract a quarter and wrap around to 4 if 0
  const priorQuarter = (lastQuarter - 1 + 4) % 4 || 4;

  return {
    // The quarter before the current one
    lastQuarterISO: toMonthISO(lastQuarterYear, `Q${lastQuarter}`),

    // The quarter before Last Quarter
    priorQuarterISO: toMonthISO(priorQuarterYear, `Q${priorQuarter}`),

    // Last Quarter but for the previous fiscal year
    priorYearQuarterISO: toMonthISO(lastQuarterYear - 1, `Q${lastQuarter}`),
  };
};

/**
 * Used to select predefined period and compare period values. These consider both calendar year and
 * the fiscal year. Below are some examples of the inputs and outputs that are expected:
 *
 * @example
 * Using March 2025 as the current date and December 31st as the fiscal year end: (We're in Q1 of fiscal year 2025)
 * - last month         (calendar year)   2025-02
 * - prior month        (calendar year)   2025-01
 * - prior year month   (calendar year)   2024-02
 * - last quarter       (fiscal year)     2024-Q4
 * - prior quarter      (fiscal year)     2024-Q3
 * - prior year quarter (fiscal year)     2023-Q4
 * - last year          (fiscal year)     2024-FY
 * - prior year         (fiscal year)     2023-FY
 *
 * @example
 * Using March 2025 as the current date and January 31st as the fiscal year end: (We're in Q1 of fiscal year 2026)
 * - last month         (calendar year)   2025-02
 * - prior month        (calendar year)   2025-01
 * - prior year month   (calendar year)   2024-02
 * - last quarter       (fiscal year)     2025-Q4
 * - prior quarter      (fiscal year)     2025-Q3
 * - prior year quarter (fiscal year)     2024-Q4
 * - last year          (fiscal year)     2025-FY
 * - prior year         (fiscal year)     2024-FY
 */
const PresetComparisons = ({
  popupId = `PresetComparisons-${+new Date()}`,
  onOptionClick,
  fiscalYearEnd,
  disabled = false,
}) => {
  const popupState = usePopupState({variant: 'popover', popupId});
  const [fiscalEndMonth] = fiscalYearEnd.split('-').map(Number);
  const currentFiscalYear = getCurrentFiscalYear(fiscalEndMonth);

  function handleClick (values) {
    popupState.close();
    onOptionClick(values);
  }

  // ---------------------------------------------------------------------------------------------
  // CALENDAR YEAR OPTIONS
  // ---------------------------------------------------------------------------------------------
  const lastMonth = subtractMonth(new Date(), 1);
  const lastMonthISO = toMonthISO(lastMonth.getFullYear(), lastMonth.getMonth() + 1);

  // The last month last year, so if it is currently March 2025 this would be February 2024
  const priorYearMonth = subtractMonth(lastMonth, 12);
  const priorYearMonthISO = toMonthISO(priorYearMonth.getFullYear(), priorYearMonth.getMonth() + 1);

  // The month before the last one, so if it is currently March this would be January
  const priorMonth = subtractMonth(lastMonth, 1);
  const priorMonthISO = toMonthISO(priorMonth.getFullYear(), priorMonth.getMonth() + 1);

  // ---------------------------------------------------------------------------------------------
  // FISCAL YEAR OPTIONS
  // ---------------------------------------------------------------------------------------------
  const lastFiscalYearISO = toMonthISO(currentFiscalYear - 1, 'FY');
  const priorFiscalYearISO = toMonthISO(currentFiscalYear - 2, 'FY');
  const {lastQuarterISO, priorQuarterISO, priorYearQuarterISO} = getQuarterInfo(getCurrentMonth(), fiscalEndMonth);

  return (
    <Box>
      <IconButton
        {...bindTrigger(popupState)}
        color="black"
        size="large"
        disabled={disabled}
        sx={{
          color: popupState.isOpen ? '#34A290' : 'initial',
          backgroundColor: popupState.isOpen ? '#E9F5F0' : 'initial',
        }}
      >
        <MenuIcon size={16} />
      </IconButton>
      <CascadingMenu popupState={popupState} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          flexDirection='row'
          pt={1} pr={2} pb={1} pl={2.5}
          borderBottom={`1px solid ${GREY_BORDER}`}
        >
          <Typography variant="caption" fontWeight="bold">Preset comparisons</Typography>
          <MuiIconButton onClick={popupState.close} aria-label="Close predefined comparisons">
            <XIcon size={16} color={GREY_SUBTLE} />
          </MuiIconButton>
       </Grid>
       <Grid p={1}>
       <MenuItem onClick={() => handleClick([lastMonthISO, priorMonthISO])}>
          Last Month vs. Prior Month
        </MenuItem>
        <MenuItem onClick={() => handleClick([lastMonthISO, priorYearMonthISO])}>
          Last Month vs. Prior Year Month
        </MenuItem>
        <MenuItem onClick={() => handleClick([lastQuarterISO, priorQuarterISO])}>
          Last Quarter vs. Prior Quarter
        </MenuItem>
        <MenuItem onClick={() => handleClick([lastQuarterISO, priorYearQuarterISO])}>
          Last Quarter vs. Prior Year Quarter
        </MenuItem>
        <MenuItem onClick={() => handleClick([lastFiscalYearISO, priorFiscalYearISO])}>
          Last Year vs. Prior Year
        </MenuItem>
       </Grid>
      </CascadingMenu>
    </Box>
  );
};

PresetComparisons.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  fiscalYearEnd: PropTypes.string.isRequired,
  popupId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default memo(PresetComparisons);
